/* Logic */
import Router from '@/router'
import {
    useLoadingScreenInterface, useInterface
} from '@/store/interface'
import { objectHasChanged } from '@/library/scripts/utils/object'
import {
    useUserAuthentication,
    useUserConfig,
    useUserLoadedData
} from '@/store/user'

/* Config */
import {
    DO_NOT_REQUIRE_AUTHENTICATION_ROUTES,
    TECHNICAL_ROUTES,
    ROUTES
} from '@/router/router.config'

/* Types */
import {
    RouterProjectRoute,
    RouterRegisteredRoute
} from './router.types'
import {
    NavigationFailure,
    RouteLocationNormalized
} from 'vue-router'

function _dontRequireAuthentication (toPath: string): boolean {
    return DO_NOT_REQUIRE_AUTHENTICATION_ROUTES
        .some((route) => {
            return route.path === toPath
        })
}

function _storeHistoryRecord (route: RouteLocationNormalized | RouterRegisteredRoute): void {
    const interfaceStore = useInterface()
    const lastLoadedRoute = interfaceStore.routerHistory[interfaceStore.routerHistory.length - 1]

    if (lastLoadedRoute?.path !== route.path) {
        const name = route.name ? String(route.name) : ``

        interfaceStore.routerHistory.push({
            hash: route.hash,
            name,
            params: route.params,
            path: route.path,
            query: route.query
        })
    }
}

export function removeLastHistoryRecord (): void {
    const interfaceStore = useInterface()
    const lastLoadedRoute = interfaceStore.routerHistory[interfaceStore.routerHistory.length - 1]

    if (lastLoadedRoute?.path) {
        interfaceStore.routerHistory.pop()
    }
}

/*
 * In order for user to be allowed to navigate the application he/she has to path both steps
 * Step 1:
 * accessAllowed (initialised) - Firebase Auth has been initialised
 * Step 2:
 * isNotAuthenticatedUser - User has been initialised, but didn't authenticate
 **/
export function beforeEachGuard (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
): any {
    const {
        initialised,
        authenticated
    } = useUserAuthentication()
    const { personalData } = useUserLoadedData()
    const { routerHistory } = useInterface()
    const toPath = to.path
    const isFoundRoute = to.name
    let accessAllowed = initialised

    if (accessAllowed) {
        const dontRequireAuthentication = _dontRequireAuthentication(toPath)

        switch (toPath) {
            case ROUTES.authenticate.path:
                const userConfig = useUserConfig()

                accessAllowed = !authenticated || userConfig.anonymous
                break
            case ROUTES.personalise.path:
                accessAllowed = !personalData
                break
            default:
                accessAllowed = dontRequireAuthentication || (authenticated && personalData)
        }
    }

    _storeHistoryRecord(to)

    // 3. Redirect if allowed
    if (accessAllowed && isFoundRoute) {
        const loadingScreenInterface = useLoadingScreenInterface()

        next()

        if (loadingScreenInterface.visibility) {
            loadingScreenInterface.hide()
        }
    } else if (!isFoundRoute) {
        next(ROUTES.notFound)
    } else if (to.name === ROUTES.initial.name && routerHistory.length > 0) {
        // window.history.go(-1)
    } else {
        // If not added to the codebase will break browsers back/forward button navigation
        next(false)
    }
}

export function navigateTo (
    route: RouterProjectRoute | RouterRegisteredRoute | RouteLocationNormalized
): Promise<void | NavigationFailure> {
    const { name, path, query, hash, params } = Router.currentRoute.value
    const currentRoute: RouterRegisteredRoute = {
        name: String(name),
        hash,
        params,
        path,
        query
    }

    if (objectHasChanged(currentRoute, route)) {
        return Router.push(route)
    } else {
        return Promise.resolve()
    }
}

function isAnonymousLinkWithEmail (route: RouterRegisteredRoute): boolean {
    const { anonymous } = useUserConfig()
    let isAnonymousLinkWithEmail = false

    if (anonymous) {
        const isAuthenticateRoute = route.path === ROUTES.authenticate.path

        if (isAuthenticateRoute) {
            if (route.query?.emailVerify) {
                isAnonymousLinkWithEmail = true
            }
        }
    }

    return isAnonymousLinkWithEmail
}

/*
 * Load initial route only if:
 * - Initial route is not `/`
 * - User is authenticating from email link
 **/
export function loadAppropriateInitialRoute (): void {
    const { authenticated } = useUserAuthentication()
    const interfaceStore = useInterface()
    const lastLoadedRoute = interfaceStore.routerHistory[interfaceStore.routerHistory.length - 1]
    let route = ROUTES.intro

    switch (true) {
        case !authenticated && lastLoadedRoute?.path === ROUTES.authenticate.path:
        case !authenticated && _dontRequireAuthentication(lastLoadedRoute?.path):
            route = lastLoadedRoute
            break
        case authenticated && !!lastLoadedRoute: {
            const redirectToBalanceRoutes = [
                ROUTES.authenticate,
                ROUTES.initial,
                ROUTES.intro,
                ROUTES.personalise,
                ROUTES.notFound,
                TECHNICAL_ROUTES.initialIOSPWA
            ]
            const noRedirectToBalance = redirectToBalanceRoutes.every((route) => {
                return route.path !== lastLoadedRoute.path
            })

            if (noRedirectToBalance || isAnonymousLinkWithEmail(lastLoadedRoute)) {
                route = lastLoadedRoute
            } else {
                route = ROUTES.balance
            }

            break
        }
        case authenticated:
            route = ROUTES.balance
    }

    navigateTo(route)
}
