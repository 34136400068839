/* Logic */
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        italic: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        inactive: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    }
})
