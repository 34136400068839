<template>
    <p class='text-text2 duration-action'
       :class='{
            "italic": italic,
            "text-primary": !inactive && !error,
            "text-error": error,
            "text-inactive": inactive,
            "font-medium": medium,
        }'>
        <slot />
    </p>
</template>

<script>
import Text from './text.module.ts'

export default {
    extends: Text
}
</script>
