/* Types */
import { ObjectWithNumbers } from '../types/types'

export const BREAKPOINTS: ObjectWithNumbers = {
    xsm: 0,
    xs_plus: 640,
    sm: 768,
    md: 1024,
    lg: 1268,
    xl: 1366
}
