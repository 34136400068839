/* Config */
import { ROUTES } from '@/router/router.config'

export default [
    {
        ...ROUTES.recipes,
        component: () => import(/* webpackChunkName: `recipes` */ `@/views/recipes/recipes.model.vue`),
        children: [
            {
                ...ROUTES.recipeEditor,
                component: () => import(/* webpackChunkName: `recipesEditor` */ `@/views/recipeEditor/recipeEditor.model.vue`)
            }
        ]
    }
]
