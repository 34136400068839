/* Logic */
import { defineComponent } from 'vue'

/* Components */
import ConfirmationOverlay from './components/confirmationOverlay/confirmationOverlay.model.vue'
import Footer from './components/footer/footer.model.vue'
import LoadingScreen from './components/loadingScreen/loadingScreen.model.vue'
import Navigation from './components/navigation/navigation.model.vue'
import Notifications from './components/notifications/notifications.model.vue'
import VersionUpdater from './components/versionUpdater/versionUpdater.model.vue'

export default defineComponent({
    components: {
        ConfirmationOverlay,
        Footer,
        LoadingScreen,
        Navigation,
        Notifications,
        VersionUpdater,
    }
})
