/* Logic */
import { defineStore } from 'pinia'

export const useProfileInterface = defineStore(`interface/profile`, {
    state: () => ({
        components: {
            userProducts: {
                visibility: true
            },
            userProduct: {
                visibility: false,
                id: ``
            },
            userProductMeasure: {
                visibility: false,
                id: ``,
                defaultMeasure: false
            }
        }
    }),
    actions: {
        showUserProduct (id: string): void {
            this.$patch({
                components: {
                    userProduct: {
                        visibility: true,
                        id
                    }
                }
            })
        },
        hideUserProduct (): void {
            this.$patch({
                components: {
                    userProduct: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        showUserProductMeasure (
            id: string,
            defaultMeasure: boolean
        ): void {
            this.$patch({
                components: {
                    userProductMeasure: {
                        defaultMeasure,
                        id,
                        visibility: true
                    }
                }
            })
        },
        hideUserProductMeasure (): void {
            this.$patch({
                components: {
                    userProductMeasure: {
                        id: ``,
                        visibility: false
                    }
                }
            })
        }
    }
})
