/* Logic */
import { defineComponent } from 'vue'
import { navigateTo } from '@/router/router.utils'

/* Config */
import { ROUTES } from '@/router/router.config'
import { ICONS } from '@/components/icon/icon.config'

/* Components */
import DefaultNavigation from '@/components/base/components/navigation/components/defaultNavigation/defaultNavigation.model.vue'
import IconButton from '@/components/buttons/iconButton/iconButton.model.vue'
import NavigationContainer from '@/components/containers/navigationContainer.model.vue'

export default defineComponent({
    components: {
        IconButton,
        DefaultNavigation,
        NavigationContainer
    },
    setup () {
        return {
            ICONS,
            ROUTES,
            navigateTo
        }
    },
    computed: {
        settingsVisibility (): boolean {
            const allowedIn = [
                ROUTES.profile,
                ROUTES.settings
            ]
            const currentRoutePath = this.$route.path

            return allowedIn.some((route) => {
                return route.path === currentRoutePath
            })
        }
    }
})
