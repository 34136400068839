import { createValuesSameAsKeys } from '@/library/scripts/utils/object'

export const IMAGE_PROPS = {
    showShadow: {
        type: Boolean,
        default: false
    },
    showError: {
        type: Boolean,
        default: false
    },
    alt: {
        type: String,
        required: true
    },
    fireStorage: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ``
    },
    imagePath: {
        type: String,
        required: true
    },
    imageClass: {
        type: String,
        required: false
    },
    placeholderClass: {
        type: String,
        required: false
    }
}

export const ASSETS_FOLDERS = createValuesSameAsKeys({
    images: ``,
    icons: ``,
})

export const CONFIG = {
    assetsFolders: ASSETS_FOLDERS
}
