/* eslint-disable no-console */
/* Logic */
import { register } from 'register-service-worker'

/* Config */
import {
    CONFIG,
    SERVICE_WORKER_EVENTS
} from './serviceWorker.config'

if (process.env.NODE_ENV === `production`) {
    register(`${process.env.BASE_URL}${CONFIG.pathToServiceWorker}`, {
        ready () {
            console.log(`Service worker is active.`)
        },
        registered (registration) {
            console.log(`Service worker has been registered.`)

            /*
      Perform routinely check for app updates by testing for a new service worker */
            setInterval(() => {
                registration.update()
            }, CONFIG.updateCheckInterval)
        },
        cached () {
            console.log(`Content has been cached for offline use.`)
        },
        updatefound () {
            console.log(`New content is downloading.`)
        },
        updated (registration) {
            console.log(`New content is available please refresh.`)

            document.dispatchEvent(
                new CustomEvent(SERVICE_WORKER_EVENTS.updated, { detail: registration })
            )
        },
        offline () {
            console.log(`No internet connection found. App is running in offline mode.`)
        },
        error (error) {
            console.error(`Error during service worker registration:`, error)
        }
    })
}
