/* Logic */
import {
    ref,
    defineComponent
} from 'vue'
import { useLoadingScreenInterface } from '@/store/interface'
import { storeToRefs } from 'pinia'

/* Config */
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { CONFIG } from './loadingScreen.config'
import { NOTIFICATIONS } from '@/library/scripts/values/notifications'
import { GLOBAL_CLASSES } from '@/library/scripts/values/classes'

/* Types */
import { BrowserTimeout } from '@/library/scripts/types/types'

/* Components */
import Icon from '@/components/icon/icon.model.vue'
import Heading4 from '@/components/text/headings/heading4.model.vue'

export default defineComponent({
    components: {
        Icon,
        Heading4
    },
    setup () {
        const loadingText = ref(CONFIG.DEFAULT_LOADING_TEXT)
        const loadingPercentage = ref(0)
        const animationSteps = ref<BrowserTimeout[]>([])
        const notifyLoadingErrorTimeout = ref<BrowserTimeout | undefined>(undefined)
        const notifyLoadingErrorCallBackTimeout = ref<BrowserTimeout | undefined>(undefined)
        const loadingScreenInterface = useLoadingScreenInterface()
        const { visibility } = storeToRefs(loadingScreenInterface)
        const loadingScreenIconHTML = ref(``)

        return {
            loadingScreenInterface,
            loadingText,
            loadingPercentage,
            visibility,
            animationSteps,
            loadingScreenIconHTML,
            notifyLoadingErrorTimeout,
            notifyLoadingErrorCallBackTimeout
        }
    },
    computed: {
        loadingBarStyle (): string {
            let scaleX: number
            const percentage = this.loadingPercentage

            if (percentage >= 100) {
                scaleX = 1
            } else {
                scaleX = percentage / 100
            }

            return `transform: scaleX(${scaleX})`
        }
    },
    methods: {
        clearAnimateLoading (): void {
            const { animationSteps } = this

            for (const step in animationSteps) {
                clearTimeout(animationSteps[step])
            }

            this.animationSteps = []
        },
        clearNotifyLoadingError (): void {
            if (this.notifyLoadingErrorTimeout) {
                clearTimeout(this.notifyLoadingErrorTimeout)

                if (this.notifyLoadingErrorCallBackTimeout) {
                    clearTimeout(this.notifyLoadingErrorCallBackTimeout)
                }

                this.notifyLoadingErrorTimeout = undefined
                this.notifyLoadingErrorCallBackTimeout = undefined
            }
        },
        notifyLoadingError (): void {
            this.clearNotifyLoadingError()

            this.notifyLoadingErrorTimeout = setTimeout(() => {
                this.$events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.loadingError)

                this.notifyLoadingErrorCallBackTimeout = setTimeout(() => {
                    location.reload()
                }, CONFIG.reloadDelay)
            }, CONFIG.notifyErrorDelay)
        },
        hideLoadingScreen (): void {
            this.clearAnimateLoading()
            this.clearNotifyLoadingError()

            this.loadingPercentage = 100

            setTimeout(() => {
                this.loadingScreenInterface.hide()
            }, CONFIG.hideDelay)
        },
        showLoadingScreen (loadingType: string): void {
            if (this.visibility) {
                this.clearAnimateLoading()
            } else {
                this.loadingPercentage = 0
            }

            this.loadingScreenInterface.show()

            this.animateLoading(
                CONFIG.LOADING_TEXTS[loadingType]
            )
        },
        animateLoading (loadingTexts: string[]): void {
            const progress = this.createRandomProgress()
            const progressLength = progress.length
            const loadingsTextsLength = loadingTexts.length
            const progressStepsPerText = progressLength / loadingsTextsLength

            progress.forEach((step, index) => {
                if (index <= progressLength) {
                    this.animationSteps.push(
                        setTimeout(() => {
                            this.loadingText = loadingTexts[Math.floor(index / progressStepsPerText)]
                            this.loadingPercentage = step
                        }, 100 * index)
                    )
                }
            })
        },
        createRandomProgress (): number[] {
            const max = 90
            const min = this.loadingPercentage // Even if the loading type changes continue where it was left
            const minSteps = 16
            const progress = []
            const stepWidth = (max - min) / minSteps // define how wide can the average step be at most
            let lastNumber = 0

            while (lastNumber < max) {
                lastNumber = lastNumber + Math.floor(Math.random() * stepWidth + 1)
                progress.push(lastNumber)
            }

            return progress
        }
    },
    watch: {
        visibility (value: boolean): void {
            if (value) {
                this.notifyLoadingError()
                this.animateLoading(CONFIG.LOADING_TEXTS.default)
            } else {
                this.clearAnimateLoading()
                this.clearNotifyLoadingError()
            }
        }
    },
    created (): void {
        const loadingScreenᴱ = document.getElementsByClassName(GLOBAL_CLASSES.loadingScreen)[0]
        const loadingScreenIconᴱ = document.getElementsByClassName(GLOBAL_CLASSES.loadingScreenIcon)[0]

        if (this.loadingScreenInterface.visibility) {
            this.showLoadingScreen(CONFIG.LOADING_SCREEN_TYPES.initialisation)
        } else {
            this.hideLoadingScreen()
        }

        if (loadingScreenIconᴱ) {
            this.loadingScreenIconHTML = loadingScreenIconᴱ.outerHTML
        }

        loadingScreenᴱ?.remove()
    }
})
