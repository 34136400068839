/* Logic */
import {
    defineComponent,
    ref
} from 'vue'
import { capitalizeString } from '@/library/scripts/utils/string'
import {
    navigateTo,
    removeLastHistoryRecord
} from '@/router/router.utils'
import { cloneDeep } from '@/library/scripts/utils/object'
import { useInterface } from '@/store/interface'
import { useUserAuthentication } from '@/store/user'

/* Config */
import { CONFIG } from './backButton.config'
import { ROUTES } from '@/router/router.config'

/* Types */
import { InterfaceRouterHistoryRoute } from '@/store/interface/types'

/* Component */
import NavigationContainer from '@/components/containers/navigationContainer.model.vue'
import BackButton from '@/components/buttons/backButton/backButton.model.vue'

export default defineComponent({
    components: {
        NavigationContainer,
        BackButton
    },
    setup () {
        const lastScrollY = ref(0)
        const scrollListenerThrottled = ref(false)
        const slideDownBackButton = ref(true)
        const interfaceStore = useInterface()
        const userAuthentication = useUserAuthentication()

        return {
            userAuthentication,
            interfaceStore,
            lastScrollY,
            scrollListenerThrottled,
            slideDownBackButton
        }
    },
    computed: {
        lastLoadedRoute (): InterfaceRouterHistoryRoute {
            const lastLoadedRoute = this.interfaceStore.routerHistory[this.interfaceStore.routerHistory.length - 2]
            let route = ROUTES.authenticate

            if (this.userAuthentication.authenticated) {
                route = ROUTES.profile
            }

            if (lastLoadedRoute) {
                const isNotNonFunctionalRoute = CONFIG.NON_FUNCTIONAL_ROUTES
                    .every((route) => {
                        return route.path !== lastLoadedRoute.path
                    })

                if (isNotNonFunctionalRoute) {
                    route = lastLoadedRoute
                }
            }

            return route
        },
        backButtonText (): string {
            let backButtonText = CONFIG.defaultBackButtonText

            if (this.lastLoadedRoute.name) {
                backButtonText = this.lastLoadedRoute.name
                    .split(/(?=[A-Z])/)
                    .join(` `)
            }

            return capitalizeString(backButtonText)
        }
    },
    methods: {
        navigateBack (): void {
            /**
             * The route has to be stored as detached object in order to:
             * avoid being updated when the history record will be removed */
            const lastLoadedRoute = cloneDeep(this.lastLoadedRoute)

            removeLastHistoryRecord()
            navigateTo(lastLoadedRoute)
        },
        controlBackButtonPosition (): void {
            if (this.scrollListenerThrottled) {
                return
            }

            window.requestAnimationFrame(() => {
                const { scrollY } = window

                switch (true) {
                    case this.lastScrollY < scrollY &&
                    scrollY > CONFIG.navigationVisibilityThreshold:
                        this.slideDownBackButton = false
                        break
                    case this.lastScrollY > scrollY:
                        this.slideDownBackButton = true
                }

                this.lastScrollY = scrollY
                this.scrollListenerThrottled = false
            })

            this.scrollListenerThrottled = true
        },
        scrollToTheTop (): void {
            window.scrollTo({
                behavior: `smooth`,
                left: 0,
                top: 0
            })
        },
        addListeners (): void {
            window.addEventListener(`scroll`, this.controlBackButtonPosition)
        }
    },

    mounted (): void {
        this.addListeners()
    }
})
