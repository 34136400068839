/* Logic */
import { defineComponent } from 'vue'

/* Config */
import { CONFIG } from './icon.config'

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        }
    },
    setup (props) {
        const { origin } = window.location
        const iconPath = `${origin}/${CONFIG.svgMap}#${props.icon}`

        return {
            iconPath
        }
    }
})
