/* Logic */
import { defineComponent } from 'vue'
import { useBalanceInterface } from '@/store/interface'
import { useUserAuthentication } from '@/store/user'
import { createEnergyRecommendations } from '@/components/mealPlan/mealPlan.utils'
import { useProductsStore } from '@/store/products'
import { findProductMeasure } from '@/library/scripts/utils/measures'
import { useRecipesStore } from '@/store/recipes'
import MealPlanBase from '@/components/mealPlan/mealPlan.module'

/* Config */
import { CONFIG } from '@/views/balance/components/mealsPlan/components/mealPlan/mealPlan.config'
import { BALANCE_RECORD_COMPLETENESS } from '@/library/scripts/plugins/userDataManager/userDataManager.config'

/* Types */
import {
    BalanceRecordCompleteness,
    BalanceRecordDefaultCompletenessValues
} from '@/library/scripts/types/balanceRecord'
import { EnergyRecommendations } from '@/components/mealPlan/mealPlan.types'

/* Components */
import ImageComponent from '@/components/image/image.model.vue'
import Text2 from '@/components/text/text/text2.model.vue'
import Heading5 from '@/components/text/headings/heading5.model.vue'
import Heading4 from '@/components/text/headings/heading4.model.vue'

export default defineComponent({
    extends: MealPlanBase,
    props: {
        isUnfolded: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        selfDistance: {
            type: Boolean,
            default: true
        }
    },
    setup () {
        const balanceInterface = useBalanceInterface()
        const productsStore = useProductsStore()
        const recipesStore = useRecipesStore()
        const userAuthentication = useUserAuthentication()

        return {
            balanceInterface,
            productsStore,
            recipesStore,
            userAuthentication
        }
    },
    components: {
        ImageComponent,
        Text2,
        Heading5,
        Heading4
    },
    computed: {
        completeness (): BalanceRecordCompleteness {
            let completeness = BALANCE_RECORD_COMPLETENESS

            if (this.balanceRecord.completeness) {
                completeness = this.balanceRecord.completeness
            }

            return completeness
        },
        energyCompleteness (): BalanceRecordDefaultCompletenessValues {
            return this.completeness.energy
        },
        energyRecommendations (): EnergyRecommendations {
            return createEnergyRecommendations(this.energyCompleteness, this.mealPlanName)
        },
        energyDescription (): string {
            const { recipesStore } = this
            let current = 0
            let description: string

            this.mealPlanProducts.forEach((product) => {
                const storeProduct = this.productsStore.getProduct(product.id)

                if (storeProduct) {
                    const measure = findProductMeasure(storeProduct.measures, product.measureId)

                    if (measure) {
                        const { energy } = storeProduct.nutrients

                        current += product.quantity * measure.weight * energy
                    }
                }
            })

            this.mealPlanRecipes.forEach((recipe) => {
                const storeRecipe = recipesStore.getRecipe(recipe.id)

                if (storeRecipe) {
                    const { energy } = storeRecipe.nutrients

                    current += recipe.quantity * energy
                }
            })

            switch (true) {
                case current > 0:
                    description = `${Math.round(current)} of ${this.energyRecommendations.from} ${CONFIG.defaultUnit}`
                    break
                case this.energyRecommendations.to > 0:
                    description = `Recommended ${this.energyRecommendations.from} - ${this.energyRecommendations.to} ${CONFIG.defaultUnit}`
                    break
                default:
                    description = `Recommended ${this.energyRecommendations.from} ${CONFIG.defaultUnit}`
            }

            return description
        },
        foldedItemsList (): string {
            const list: string[] = []

            this.mealPlanRecipes.forEach((recipe) => {
                list.push(recipe.name)
            })

            this.mealPlanProducts.forEach((selectedProduct) => {
                const product = this.productsStore.getProduct(selectedProduct.id)

                if (product) {
                    list.push(product.name)
                }
            })

            return list.join(`, `)
        }
    }
})
