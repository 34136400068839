/* Logic */
import { defineStore } from 'pinia'
import { isEmptyObject } from '@/library/scripts/utils/object'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { UserProducts } from './types'
import { ObjectWithStrings } from '@/library/scripts/types/types'

const products: UserProducts = {}

export const useUserProductsStore = defineStore(`user/products`, {
    state: () => {
        return {
            products: cloneDeep(products)
        }
    },
    getters: {
        categories (state) {
            const categories: ObjectWithStrings = {}
            const { products } = state

            for (const productId in products) {
                const { category } = products[productId]

                if (category.length && !categories[category]) {
                    categories[category] = category
                }
            }

            return categories
        },
        hasProducts (state) {
            return !isEmptyObject(state.products)
        },
        productsQuantity (state) {
            return Object.keys(state.products).length
        }
    }
})
