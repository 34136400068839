/* Logic */
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        dropShadow: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        inactive: {
            type: Boolean,
            default: false
        },
        smallerText: {
            type: Boolean,
            default: false
        },
        enableHoverColor: {
            type: Boolean,
            default: true
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        alignLeft: {
            type: Boolean,
            default: false
        },
        capitalize: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            default: `button`
        },
        disabled: {
            type: Boolean,
            default: false
        },
        alignTextLeft: {
            type: Boolean,
            default: false
        }
    }
})
