/* Logic */
import {
    cloneDeep,
    hasOwnProperty
} from '@/library/scripts/utils/object'
import { FirebaseFS } from '@/database'
import { handleDatabaseErrors } from '@/library/scripts/utils/database'
import { useRecipesStore } from '@/store/recipes'
import { handleRecipesChanges } from '@/library/scripts/plugins/userDataManager/userDataManager.utils.handlers'

/* Config */
import {
    ANONYMOUS_USER_WELCOME_OPTIONS,
    FIRESTORE_COLLECTIONS,
    FIRESTORE_USERDATA_DOCUMENTS
} from './userDataManager.config'
import { ROUTES } from '@/router/router.config'

/* Types */
import { LoadedData } from '@/store/user/loadedData/types'
import { PersonalData } from '@/store/user/personalData/types'
import { Config } from '@/store/user/config/types'
import { BalanceRecordTimestamp } from '@/library/scripts/types/balanceRecord'
import {
    QuerySnapshot,
    DocumentChange,
    collection,
    getDocs
} from 'firebase/firestore'
import {
    UserDataSnapshotChanges,
    BalanceRecordSnapshotChanges,
    RecipesSnapshotChanges
} from './userDataManager.types'
import { UserProducts } from '@/store/user/products/types'
import {
    RecipeAuthors,
    Recipes
} from '@/library/scripts/types/recipe'
import { useUserConfig } from '@/store/user'
import { useConfirmationOverlayInterface } from '@/store/interface/confirmationOverlay'
import { updateUserData } from '@/library/scripts/plugins/userDataManager/actions/userData'
import { navigateTo } from '@/router/router.utils'

/*
The following utility is used to retrieve the timestamp instead of getTimestampFromDate,
because passing date as `YEAR/MM/DAY` format to JavaScript new Date() will subtract 1 day from the MM value
and will create the date with previous month */
function _getBalanceRecordTimestamp (ref: string): BalanceRecordTimestamp {
    const timestamp = ref.split(FIRESTORE_COLLECTIONS.balanceRecords)[1].replace(`/`, ``)
    const timestampParts = timestamp.split(`/`)

    return {
        day: parseFloat(timestampParts[2]),
        month: parseFloat(timestampParts[1]),
        year: parseFloat(timestampParts[0])
    }
}

export function getAmountOfRelevantDaysInTheMonth (
    userCreationTimestamp: BalanceRecordTimestamp,
    timestamp: BalanceRecordTimestamp
): number[] {
    const { year, month } = timestamp
    const daysInMonth = new Date(year, month + 1, 0).getDate()
    let relevantDays = []

    for (let n = 1; n <= daysInMonth; n++) {
        relevantDays.push(n)
    }

    if (userCreationTimestamp.year === year && userCreationTimestamp.month === month) {
        relevantDays = relevantDays.slice(userCreationTimestamp.day - 1)
    }

    return relevantDays
}

export function getUserDataSnapshotChanges (querySnapshot: QuerySnapshot): UserDataSnapshotChanges {
    const changes: UserDataSnapshotChanges = {
        data: {},
        changeType: ``,
        isEmpty: querySnapshot.empty
    }

    querySnapshot.docChanges().forEach((docChange: DocumentChange) => {
        const { doc } = docChange

        changes.data[doc.id] = doc.data() as Config | PersonalData | UserProducts | Recipes
        changes.changeType = docChange.type
    })

    return changes
}

export function getRecipesDataSnapshotChanges (querySnapshot: QuerySnapshot): RecipesSnapshotChanges {
    const changes: RecipesSnapshotChanges = {
        data: {},
        changeType: ``,
        isEmpty: querySnapshot.empty
    }

    querySnapshot.docChanges().forEach((docChange: DocumentChange) => {
        const { doc } = docChange

        changes.data[doc.id] = doc.data() as Recipes | RecipeAuthors
        changes.changeType = docChange.type
    })

    return changes
}

export function getBalanceRecordsSnapshotChanges (querySnapshot: QuerySnapshot): BalanceRecordSnapshotChanges {
    const changes: BalanceRecordSnapshotChanges = {
        data: {},
        changeType: ``,
        isEmpty: querySnapshot.empty,
        timestamp: getTodayTimestamp()
    }

    querySnapshot.docChanges().forEach((docChange: DocumentChange) => {
        const { doc } = docChange

        changes.data[doc.id] = doc.data()
        changes.changeType = docChange.type
        changes.timestamp = _getBalanceRecordTimestamp(doc.ref.path)
    })

    return changes
}

export function getBalanceModelPath (personalData: PersonalData): string {
    const { age } = personalData
    let pathToModel = personalData.gender

    switch (true) {
        case age < 31:
            pathToModel += `18`
            break
        case age >= 31 && age < 51:
            pathToModel += `31`
            break
        case age >= 51:
            pathToModel += `51`
    }

    return pathToModel
}

export function getTimestampFromDate (dateValue: Date | string | number): BalanceRecordTimestamp {
    const date = new Date(dateValue)

    return {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
    }
}

export function getTodayTimestamp (): BalanceRecordTimestamp {
    const today = new Date()

    return getTimestampFromDate(today)
}

export function observeDataLoad (
    object: LoadedData,
    callback: Function
) {
    for (const propertyName in object) {
        if (hasOwnProperty(object, propertyName)) {
            let value = object[propertyName]

            Object.defineProperty(object, propertyName, {
                get () {
                    return value
                },
                set (newValue) {
                    value = newValue
                    callback(propertyName)
                }
            })
        }
    }
}

export function unObserveDataLoad (object: LoadedData) {
    return cloneDeep(object)
}

export function getLoadedDataInitialValues (): LoadedData {
    return {
        balanceModel: false,
        balanceRecords: false,
        config: false,
        personalData: false,
        userProducts: true,
        userRecipes: false,
        products: false,
        recipes: false
    }
}

/*
Ternary check is used for now, since in Firebase type user.metadata.createTime is marked as optional
so some fallback value has to be provided.
Possible solution in the future would be to store user creationTime using server functions during user creation */
export function getUserCreationTimestamp (creationTime: string | undefined): BalanceRecordTimestamp {
    const creationDate = creationTime ? new Date(creationTime) : new Date()

    return {
        day: creationDate.getDate(),
        month: creationDate.getMonth(),
        year: creationDate.getFullYear()
    }
}

export async function loadProducts (): Promise<any> {
    const productsCollection = collection(FirebaseFS, FIRESTORE_COLLECTIONS.products)

    return getDocs(productsCollection)
        .then(response => {
            const products = {}

            response.docs.forEach((doc) => {
                Object.assign(products, doc.data())
            })

            return products
        })
        .catch(error => {
            handleDatabaseErrors(error)
        })
}

export function saveRecipes (
    recipes: Recipes
): void {
    const recipesStore = useRecipesStore()

    recipesStore.recipes = recipes
}

export function saveAuthors (
    authors: RecipeAuthors
) {
    const recipesStore = useRecipesStore()

    recipesStore.authors = authors
}

export async function loadRecipes (): Promise<any> {
    const productsCollection = collection(FirebaseFS, FIRESTORE_COLLECTIONS.recipes)

    return getDocs(productsCollection)
        .then((response) => {
            handleRecipesChanges(response)
        })
        .catch(error => {
            handleDatabaseErrors(error)
        })
}

export function welcomeAnonymousUser (): void {
    const userConfig = useUserConfig()
    const confirmationOverlay = useConfirmationOverlayInterface()
    const userConfigClone = cloneDeep(userConfig.$state)

    confirmationOverlay.show(
        ANONYMOUS_USER_WELCOME_OPTIONS,
        () => {
            navigateTo(ROUTES.profile)
        }
    )

    userConfigClone.displayWelcome = false

    updateUserData(
        [{
            value: userConfigClone,
            valueName: FIRESTORE_USERDATA_DOCUMENTS.config
        }]
    )
}
