import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex flex-col ml-18-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageComponent = _resolveComponent("ImageComponent")!
  const _component_Heading4 = _resolveComponent("Heading4")!
  const _component_Heading5 = _resolveComponent("Heading5")!
  const _component_Text2 = _resolveComponent("Text2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["last:mb-0 cursor-pointer", {
             "mb-28-14": _ctx.selfDistance && _ctx.hasMealPlanProducts,
             "mb-28-17": _ctx.selfDistance && !_ctx.hasMealPlanProducts
         }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ImageComponent, {
        class: "h-76 w-76 ml-actionAlign p-8 -mt-8 drop-shadow-smallItem",
        imagePath: `mealsPlan/${_ctx.mealPlanName}.png`,
        alt: _ctx.mealPlanName
      }, null, 8, ["imagePath", "alt"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Heading4, {
          class: "group-hover:text-active",
          active: _ctx.isSelected
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mealPlanName), 1)
          ]),
          _: 1
        }, 8, ["active"]),
        _createVNode(_component_Heading5, {
          class: "mt-13-10",
          italic: true,
          inactive: true,
          capitalize: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.energyDescription), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _withDirectives(_createVNode(_component_Text2, { class: "capitalize mt-13-4" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.foldedItemsList), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isUnfolded && _ctx.hasItems]
    ])
  ], 2))
}