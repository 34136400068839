/* Routes */
import Authenticate from './authenticate.route'
import Balance from './balance.route'
import Initial from './initial.route'
import Intro from './intro.route'
import Recipes from './recipes.route'
import NotFound from './notFound.route'
import Personalise from './personalise.route'
import PrivacyPolicy from './privacyPolicy.route'
import Profile from './profile.route'
import Settings from './settings.route'
import TermsOfService from './termsOfService.route'

const routes = [
    ...Authenticate,
    ...Balance,
    ...Initial,
    ...Intro,
    ...NotFound,
    ...Personalise,
    ...PrivacyPolicy,
    ...Profile,
    ...Recipes,
    ...Settings,
    ...TermsOfService
]

export default routes
