/* Logic */
import { defineComponent } from 'vue'
import { navigateTo } from '@/router/router.utils'

/* Config */
import { ROUTES } from '@/router/router.config'

/* Components */
import DefaultButton from '@/components/buttons/defaultButton/defaultButton.model.vue'

export default defineComponent({
    components: {
        DefaultButton
    },
    data () {
        return {
            ROUTES,
            navigateTo
        }
    },
    computed: {
        currentRoute (): String {
            return this.$route.path
        }
    }
})
