/* Logic */
import { FirebaseFS } from '@/database'
import {
    doc,
    updateDoc,
    writeBatch
} from 'firebase/firestore'
import { handleDatabaseErrors } from '@/library/scripts/utils/database'
import { getAmountOfRelevantDaysInTheMonth } from '@/library/scripts/plugins/userDataManager/userDataManager.utils'
import { createURLPath } from '@/library/scripts/utils/string'
import { useUserAuthentication } from '@/store/user'

/* Config */
import {
    BALANCE_RECORD_VALUES,
    FIRESTORE_COLLECTIONS
} from '@/library/scripts/plugins/userDataManager/userDataManager.config'

/* Types */
import { BalanceRecordTimestamp } from '@/library/scripts/types/balanceRecord'
import { UpdateBalanceRecordOptions } from '@/library/scripts/plugins/userDataManager/userDataManager.types'

export function createMissingMonthlyBalanceRecords (timestamp: BalanceRecordTimestamp) {
    const { year, month } = timestamp
    const { id, creationTimestamp } = useUserAuthentication()
    const monthlyRecordsBatch = writeBatch(FirebaseFS)
    const relevantDays = getAmountOfRelevantDaysInTheMonth(creationTimestamp, timestamp)
    const monthCollectionPath = createURLPath(
        FIRESTORE_COLLECTIONS.users, id, FIRESTORE_COLLECTIONS.balanceRecords, year.toString(), month.toString()
    )

    relevantDays.forEach(relevantDay => {
        const dayRef = doc(
            FirebaseFS,
            createURLPath(monthCollectionPath, relevantDay.toString())
        )

        monthlyRecordsBatch.set(dayRef, BALANCE_RECORD_VALUES, { merge: true })
    })

    monthlyRecordsBatch.commit()
}

export function updateBalanceRecord (options: UpdateBalanceRecordOptions): Promise<void> | undefined {
    const { value, valueName } = options
    const { id } = useUserAuthentication()

    if (valueName) {
        const { year, month, day } = options.timestamp
        const balanceRecordPath = createURLPath(
            FIRESTORE_COLLECTIONS.users,
            id,
            FIRESTORE_COLLECTIONS.balanceRecords,
            year.toString(),
            month.toString(),
            day.toString()
        )
        const balanceRecordRef = doc(FirebaseFS, balanceRecordPath)

        return updateDoc(
            balanceRecordRef, {
                [valueName]: value
            })
            .catch((error) => {
                handleDatabaseErrors(error)
            })
    }
}
