/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Config */
import { DEFAULT_OPTIONS } from '@/library/scripts/values/confirmationOverlay'

/* Types */
import { ConfirmationOverlayOptions } from '@/library/scripts/types/confirmationOverlay'

export const useConfirmationOverlayInterface = defineStore(`interface/confirmationOverlay`, {
    state: () => ({
        visibility: false,
        options: cloneDeep(DEFAULT_OPTIONS),
        confirmationCallback: () => {}
    }),
    actions: {
        show (
            options: ConfirmationOverlayOptions,
            confirmationCallback?: () => void
        ): void {
            this.options = options
            this.visibility = true

            if (confirmationCallback) {
                this.confirmationCallback = confirmationCallback
            }
        },
        hide (): void {
            this.visibility = false
            this.options = cloneDeep(DEFAULT_OPTIONS)
            this.confirmationCallback = () => {}
        }
    }
})
