import { ObjectWithStrings } from '@/library/scripts/types/types'

export function createSelectorsFromClasses <T extends ObjectWithStrings> (classesConfig: T, extraSelectorsConfig?: T): T {
    const selectorsConfig: ObjectWithStrings = {}

    Object.keys(classesConfig).forEach(key => {
        selectorsConfig[key] = `.${classesConfig[key]}`
    })

    if (extraSelectorsConfig) {
        Object.keys(extraSelectorsConfig).forEach(key => {
            selectorsConfig[key] = extraSelectorsConfig[key]
        })
    }

    return selectorsConfig as T
}
