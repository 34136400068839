/* Types */
import { RouterRegisteredRoute } from './router.types'

export const TECHNICAL_ROUTES = {
    initialIOSPWA: {
        name: null,
        path: `/index.html`
    }
}

export const ROUTES: {
    [index: string]: RouterRegisteredRoute
} = {
    authenticate: {
        name: `authenticate`,
        path: `/authenticate`
    },
    initial: {
        name: `initial`,
        path: `/`
    },
    intro: {
        name: `intro`,
        path: `/intro`
    },
    balance: {
        name: `balance`,
        path: `/balance`
    },
    recipes: {
        name: `recipes`,
        path: `/recipes`
    },
    recipeEditor: {
        name: `recipeEditor`,
        path: `/recipes/editor`
    },
    privacyPolicy: {
        name: `privacyPolicy`,
        path: `/privacy-policy`
    },
    personalise: {
        name: `personalise`,
        path: `/personalise`
    },
    profile: {
        name: `profile`,
        path: `/profile`
    },
    settings: {
        name: `settings`,
        path: `/settings`
    },
    termsOfService: {
        name: `termsOfService`,
        path: `/terms-of-service`
    },
    notFound: {
        name: `notFound`,
        path: `/not-found`
    }
}

/*
The above routes do not require user to be authenticated in order to access them */
export const DO_NOT_REQUIRE_AUTHENTICATION_ROUTES = [
    ROUTES.privacyPolicy,
    ROUTES.termsOfService,
    ROUTES.intro
]

export const AUTHENTICATE_VERIFY_EMAIL = {
    key: `emailVerify`,
    value: `true`
}
