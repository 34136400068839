import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-center gap-28-17" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultButton = _resolveComponent("DefaultButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DefaultButton, {
      alignLeft: true,
      active: _ctx.currentRoute.includes(_ctx.ROUTES.balance.path),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo(_ctx.ROUTES.balance)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.ROUTES.balance.name), 1)
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_DefaultButton, {
      active: _ctx.currentRoute.includes(_ctx.ROUTES.recipes.path),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateTo(_ctx.ROUTES.recipes)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.ROUTES.recipes.name), 1)
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_DefaultButton, {
      active: _ctx.currentRoute.includes(_ctx.ROUTES.profile.path),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo(_ctx.ROUTES.profile)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.ROUTES.profile.name), 1)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}