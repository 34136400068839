/* Logic */
import { nanoid } from 'nanoid'

/* Types */
import { ObjectWithFunctions } from '@/library/scripts/types/types'

class IO {
    private callbacks: ObjectWithFunctions = {}
    private observer = {} as IntersectionObserver

    constructor (rootMargin: string) {
        this.callbacks = {}

        this.observer = new IntersectionObserver(
            this.execute.bind(this),
            {
                threshold: 0,
                rootMargin
            }
        )
    }

    /**
     * Callbacks manager, that will check which observed target have recently entered the viewport
     * and execute the right callback for it
     */
    execute (entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const { target } = entry

                // @ts-ignore
                this.callbacks[target.id](entry)
                this.unobserve(target)
            }
        })
    }

    /**
     * Add a target to Intersection Observer in order to observe its position.
     * When position will match the viewport the provided callback will be executed
     */
    observe (
        target: Element,
        callback: () => void
    ) {
        const id = nanoid()

        target.id = id
        this.callbacks[id] = callback
        this.observer.observe(target)
    }

    unobserve (target: Element) {
        this.observer.unobserve(target)
        delete this.callbacks[target.id]
    }
}

const imagesRootMargin = `500px`

export const ImageIO = new IO(imagesRootMargin)
