/* Logic */
import {
    getDatabase,
    ref,
    onValue,
    onDisconnect,
    off,
    DataSnapshot
} from 'firebase/database'
import { useUserAuthentication } from '@/store/user'

/* Config */
import { RTDB_REFERENCES } from './userConnectionStatus.config'

export class UserConnectionStatusClass {
    private RTDB = getDatabase()
    private userId = ``

    private connectionStatusListener (snapshot: DataSnapshot) {
        /**
         * Create a reference to the special RTDB_REFERENCES.infoConnected path in Realtime Database.
         * This path returns `true` when connected and `false` when disconnected
         **/
        const userStatusDatabaseRef = ref(
            this.RTDB,
            RTDB_REFERENCES.userStatus + this.userId
        )

        /**
         * Check if already connected
         **/
        if (snapshot.val() === false) {
            const userAuthentication = useUserAuthentication()

            userAuthentication.online = false
        }

        /**
         * Will only trigger once the user has disconnected
         * by closing the app, losing internet, or any other means
         **/
        onDisconnect(userStatusDatabaseRef)
            .set(``)
            .then(function () {
                /**
                 * The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges
                 * the onDisconnect() request, NOT once we've actually disconnected:
                 * https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect **/
                const userAuthentication = useUserAuthentication()

                userAuthentication.online = true
            })
    }

    private boundConnectionStatusListener = this.connectionStatusListener.bind(this)

    private listenConnectionStatus () {
        const infoRef = ref(this.RTDB, RTDB_REFERENCES.infoConnected)

        onValue(infoRef, this.boundConnectionStatusListener)
    }

    private unListenConnectionStatus () {
        const infoRef = ref(this.RTDB, RTDB_REFERENCES.infoConnected)

        off(infoRef)
    }

    public init () {
        const userAuthentication = useUserAuthentication()

        userAuthentication.$subscribe((mutation, state) => {
            if (state.id) {
                this.userId = state.id
                this.listenConnectionStatus()
            } else if (!state.id) {
                this.unListenConnectionStatus()
            }
        })
    }
}

export const UserConnectionStatus = new UserConnectionStatusClass()
