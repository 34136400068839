import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultNavigation = _resolveComponent("DefaultNavigation")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_DefaultContainer = _resolveComponent("DefaultContainer")!
  const _component_MealsPlanShortcut = _resolveComponent("MealsPlanShortcut")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DefaultContainer, { class: "fixed bottom-0 bg-widget h-bottomNavigationHeight flex items-end shadow-widgetAngleUp rounded-t-widgetMobile z-bottomNavigation" }, {
      default: _withCtx(() => [
        _createVNode(_component_DefaultNavigation, { class: "self-center z-10" }),
        _createVNode(_component_IconButton, {
          class: "h-68 min-w-68 rounded-24 mb-18",
          dropShadow: true,
          icon: _ctx.ICONS.plus,
          iconClass: _ctx.iconClass,
          onClick: _ctx.toggleMealsPlanShortcut
        }, null, 8, ["icon", "iconClass", "onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_MealsPlanShortcut, {
      class: "z-bottomNavigationMealsPlanShortcut",
      visibility: _ctx.elementsVisibility.mealsPlanShortcut
    }, null, 8, ["visibility"])
  ], 64))
}