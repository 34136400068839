/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { BalanceModel } from '@/library/scripts/types/balanceModel'

const model = {} as BalanceModel

export const useUserBalanceModel = defineStore(`user/balanceModel`, {
    state: () => {
        return {
            model: cloneDeep(model)
        }
    }
})
