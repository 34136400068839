import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BottomNavigation = _resolveComponent("BottomNavigation")!
  const _component_TopNavigation = _resolveComponent("TopNavigation")!
  const _component_NavigationBackButton = _resolveComponent("NavigationBackButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.topAndBottomNavigationVisibility)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.bottomNavigationVisibility)
            ? (_openBlock(), _createBlock(_component_BottomNavigation, { key: 0 }))
            : (_openBlock(), _createBlock(_component_TopNavigation, { key: 1 }))
        ], 64))
      : (_ctx.backButtonVisibility)
        ? (_openBlock(), _createBlock(_component_NavigationBackButton, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}