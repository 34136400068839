/* Logic */
import { $events } from '@/index'

/* Config */
import { NOTIFICATIONS } from '@/library/scripts/values/notifications'
import { ERROR_CODES } from '@/library/scripts/values/errors'
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'

type Error = {
    code: string,
    message: string
}

export function handleDatabaseErrors (error: Error): void {
    $events.emit(GLOBAL_EVENTS.formButtonFinishProcessing)

    switch (error.code) {
        case ERROR_CODES.noConnection:
        case ERROR_CODES.unavailable:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.noConnection)
            break
        case ERROR_CODES.tooManyRequest:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.tooManyRequest)
            break
        case ERROR_CODES.emailInvalid:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.emailInvalid)
            break
        case ERROR_CODES.accountExists:
        case ERROR_CODES.credentialAlreadyInUse:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.accountExists)
            break
        case ERROR_CODES.invalidActionCode:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.invalidActionCode)
            break
        default:
            $events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.genericError)

            if (process.env.NODE_ENV === `development`) {
                // $events.emit(GLOBAL_EVENTS.notificationsShowMessage, error.message)
                /* eslint-disable no-console, no-debugger */
                // console.log(error)
                // debugger
                /* eslint-enable */
            }
    }
}
