/* Config */
import {
    NUTRIENTS_LIST_BY_CONTAINER_CATEGORY,
    CONTAINER_CATEGORIES
} from '@/library/scripts/values/nutrients'
import { NUTRIENT_SECTION_VALUES } from '@/library/scripts/values/units'
import { GENDER_OPTIONS } from '@/library/scripts/values/personalData'

/* Types */
import { ValuesConfig } from './userDataManager.types'
import {
    BalanceRecordCompleteness,
    BalanceRecordDefaultCompletenessValues,
    BalanceRecordMealPlan
} from '@/library/scripts/types/balanceRecord'

/*
Energy density is taken from https://www.wikiwand.com/en/Food_energy */
export const CONFIG: ValuesConfig = {
    genders: GENDER_OPTIONS,
    energyDensity: {
        normal: 4, /* kcal */
        lipids: 9 /* kcal */
    },
    connectionStatusListenerDuration: 100,
    energyDependent: {
        normalSections: [
            CONTAINER_CATEGORIES.carbohydrates,
            CONTAINER_CATEGORIES.proteins
        ],
        lipidsSections: [
            CONTAINER_CATEGORIES.lipids,
            ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.lipids]
        ],
        protectedValues: [NUTRIENT_SECTION_VALUES.unit]
    },
    weightDependent: {
        sections: [
            CONTAINER_CATEGORIES.proteins,
            ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.proteins]
        ],
        protectedValues: [NUTRIENT_SECTION_VALUES.AMDR, NUTRIENT_SECTION_VALUES.unit]
    },
    DRIValues: {
        unit: NUTRIENT_SECTION_VALUES.unit,
        AMDR: NUTRIENT_SECTION_VALUES.AMDR
    }
}

const MEAL_PLAN: BalanceRecordMealPlan = {
    products: [],
    recipes: []
}

const DEFAULT_COMPLETENESS_VALUES: BalanceRecordDefaultCompletenessValues = {
    current: 0,
    recommended: 0,
    percentage: 0
}

const NUTRIENTS_COMPLETENESS_VALUES = {
    currentNutrientsPercentage: 0,
    percentage: 0,
    totalNutrientsQuantity: 0
}

export const BALANCE_RECORD_COMPLETENESS: BalanceRecordCompleteness = {
    energy: { ...DEFAULT_COMPLETENESS_VALUES },
    nutrients: { ...NUTRIENTS_COMPLETENESS_VALUES },
    water: { ...DEFAULT_COMPLETENESS_VALUES }
}

export const BALANCE_RECORD_VALUES = {
    mealsPlan: {
        breakfast: MEAL_PLAN,
        dinner: MEAL_PLAN,
        lunch: MEAL_PLAN,
        snacks: MEAL_PLAN
    }
}

export const FIRESTORE_CHANGE_TYPES = {
    added: `added`,
    modified: `modified`,
    removed: `removed`
}

export const FIRESTORE_COLLECTIONS = {
    balanceModels: `balanceModels`,
    balanceRecords: `balanceRecords`,
    products: `products`,
    recipes: `recipes`,
    userData: `userData`,
    users: `users`
}

export const FIRESTORE_USERDATA_DOCUMENTS = {
    config: `config`,
    personalData: `personalData`,
    userProducts: `products`,
    recipes: `recipes`
}

export const FIRESTORE_RECIPES_DOCUMENTS = {
    authors: `authors`,
    recipes: `recipes`
}

export const FIRESTORE_BALANCE_RECORD_DATA_FIELDS = {
    mealsPlan: `mealsPlan`
}

export const ANONYMOUS_USER_WELCOME_OPTIONS = {
    title: `Welcome`,
    text: `We are glad to see you, since its your first time we have automatically created a profile for you. Would you like to edit now?`
}
