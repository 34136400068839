import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultNavigation = _resolveComponent("DefaultNavigation")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_NavigationContainer = _resolveComponent("NavigationContainer")!

  return (_openBlock(), _createBlock(_component_NavigationContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_DefaultNavigation),
      (_ctx.settingsVisibility)
        ? (_openBlock(), _createBlock(_component_IconButton, {
            key: 0,
            icon: _ctx.ICONS.settings,
            iconClass: "fill-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo(_ctx.ROUTES.settings)))
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}