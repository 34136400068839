/* Logic */
import { defineComponent } from 'vue'
import {
    lockScroll,
    unlockScroll,
    clearAllScrollLocks
} from '@/library/scripts/utils/scrollLock'
import {
    useBalanceInterface, useInterface
} from '@/store/interface'
import { storeToRefs } from 'pinia'
import { useUserBalanceRecords } from '@/store/user'
import { isEmptyObject } from '@/library/scripts/utils/object'

/* Config */
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { MEALS_PLAN_ORDER } from '@/views/balance/components/mealsPlan/mealPlans.config'
import { CONFIG } from './mealsPlanShortcut.config'
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'

/* Types */
import { BalanceRecord } from '@/library/scripts/types/balanceRecord'

/* Components */
import FoldedMealPlan from '@/components/foldedMealPlan/foldedMealPlan.model.vue'

export default defineComponent({
    props: {
        visibility: {
            type: Boolean,
            required: true
        }
    },
    setup () {
        const userBalanceRecords = useUserBalanceRecords()
        const balanceInterface = useBalanceInterface()
        const { components } = storeToRefs(balanceInterface)
        const interfaceStore = useInterface()

        return {
            userBalanceRecords,
            interfaceStore,
            balanceInterface,
            components,
            MEALS_PLAN_ORDER
        }
    },
    components: {
        FoldedMealPlan
    },
    computed: {
        hasLoadedBalanceRecord (): boolean {
            return !isEmptyObject(this.balanceRecord)
        },
        balanceRecord (): BalanceRecord {
            return this.userBalanceRecords.getRecord(this.balanceInterface.loadedRecordTimestamp)
        },
        transitionDuration (): object {
            let transitionDuration = CONFIG.transitionDuration.mobile

            if (this.interfaceStore.breakPoint !== BREAKPOINTS.xsm) {
                transitionDuration = CONFIG.transitionDuration.nonMobile
            }

            return transitionDuration
        }
    },
    methods: {
        showFoodList (mealPlan: string): void {
            this.balanceInterface.showFoodList(mealPlan)
            this.hideMealsPlanShortcut()
        },
        hideMealsPlanShortcut (): void {
            this.$events.emit(GLOBAL_EVENTS.navigationHideMealsPlanShortcut)
        },
        toggleBackgroundScroll (): void {
            if (this.visibility) {
                lockScroll(this.$el as HTMLElement)
            } else {
                unlockScroll(this.$el as HTMLElement)
            }
        },
        addListeners (): void {
            this.$watch(`visibility`, this.toggleBackgroundScroll)
        }
    },
    mounted (): void {
        this.addListeners()
    },
    beforeUnmount (): void {
        clearAllScrollLocks()
    }
})
