import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "text-action1 text-primary font-medium transition-all duration-action capitalize h-39 pt-1 ml-actionAlign group-icon flex items-center md:hover:text-hover",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_Icon, {
      class: "mr-13-8 fill-primary",
      icon: _ctx.ICONS.arrow
    }, null, 8, ["icon"]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}