type BalanceModelValue = number | string

export interface BalanceModelAMDR {
    from: BalanceModelValue,
    to: BalanceModelValue
    [index: string]: BalanceModelValue
}

export interface BalanceModelSection {
    DRI: {
        AI: BalanceModelValue,
        AMDR: BalanceModelAMDR
        EAR: BalanceModelValue,
        RDA: BalanceModelValue,
        UL: BalanceModelValue,
        unit: string
        [index: string]:
            string |
            BalanceModelValue |
            BalanceModelAMDR
    }
}

export interface BalanceModelProgressSection {
    containedSectionsQuantity: number
}

export interface BalanceModel {
    [index: string]: BalanceModelSection | BalanceModelProgressSection
    water: BalanceModelSection
    energy: BalanceModelSection
}

export function isBalanceModelSection (section: BalanceModelSection | BalanceModelProgressSection): section is BalanceModelSection {
    return (section as BalanceModelSection).DRI !== undefined
}

export function isBalanceModelProgressSection (section: BalanceModelSection | BalanceModelProgressSection): section is BalanceModelProgressSection {
    return (section as BalanceModelProgressSection).containedSectionsQuantity !== undefined
}
