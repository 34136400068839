/* Logic */
import {
    FirebaseAuth,
    FirebaseFS
} from '@/database'
import {
    onAuthStateChanged,
    User
} from 'firebase/auth'
import {
    collection,
    onSnapshot
} from 'firebase/firestore'
import {
    getTodayTimestamp,
    getUserDataSnapshotChanges,
    getBalanceRecordsSnapshotChanges,
    getLoadedDataInitialValues,
    observeDataLoad,
    unObserveDataLoad,
    getUserCreationTimestamp,
    loadProducts,
    loadRecipes,
    welcomeAnonymousUser
} from './userDataManager.utils'
import { loadAppropriateInitialRoute } from '@/router/router.utils'
import {
    handleConfigChanges,
    handlePersonalDataChanges,
    handleBalanceRecordsChanges,
    handleUserProductsChanges,
    handleUserRecipesChanges,
    handleRecipesChanges
} from '@/library/scripts/plugins/userDataManager/userDataManager.utils.handlers'
import { createURLPath } from '@/library/scripts/utils/string'
import { isUndefined } from '@/library/scripts/utils/types'
import { useProductsStore } from '@/store/products'
import { useLoadingScreenInterface } from '@/store/interface'
import {
    useUserAuthentication,
    useUserConfig,
    useUserLoadedData
} from '@/store/user'
import { getInitialUserAuthentication } from '@/store/user/authentication'

/* Config */
import { FIRESTORE_COLLECTIONS } from './userDataManager.config'

/* Types */
import { BalanceRecordTimestamp } from '@/library/scripts/types/balanceRecord'
import { ObjectWithFunctions } from '@/library/scripts/types/types'
import { UserAuthentication } from '@/store/user/authentication/types'

export class UserDataManagerClass {
    private listening = false
    private user = getInitialUserAuthentication()
    private todayTimestamp = getTodayTimestamp()
    private observingDataLoad = false
    private loadedData = getLoadedDataInitialValues()
    private balanceRecordsListeners: ObjectWithFunctions = {}

    /* Listeners Placeholders */
    private unListenUserData = () => {}
    private unListenRecipes = () => {}

    private unListenDataLoadReadinessStatus () {
        const userConfig = useUserConfig()
        this.loadedData = unObserveDataLoad(this.loadedData)



        loadAppropriateInitialRoute()

        if (userConfig.displayWelcome) {
            welcomeAnonymousUser()
        }
    }

    private listenDataLoadReadinessStatus (loadedDataName?: string): void {
        if (loadedDataName) {
            const userLoadedData = useUserLoadedData()

            userLoadedData.$patch({
                ...this.loadedData
            })
        }

        /*
        Check for all values to be true */
        if (Object.values(this.loadedData).every((loadStatus) => loadStatus)) {
            this.unListenDataLoadReadinessStatus()
        } else if (!this.observingDataLoad) {
            observeDataLoad(this.loadedData, this.listenDataLoadReadinessStatus.bind(this))
        }
    }

    private async loadProducts (): Promise<any> {
        const productsStore = useProductsStore()

        await loadProducts()
            .then((products) => {
                productsStore.$patch({
                    products
                })

                this.loadedData.products = true
            })
    }

    private async loadRecipes (): Promise<any> {
        await loadRecipes()
            .then(() => {
                this.loadedData.recipes = true
            })
    }

    private listenAdminData (): void {
        this.listenRecipes()
    }

    private listenUserData (): void {
        const userDataPath = createURLPath(FIRESTORE_COLLECTIONS.users, this.user.id, FIRESTORE_COLLECTIONS.userData)
        const userData = collection(FirebaseFS, userDataPath)

        this.unListenUserData = onSnapshot(
            userData,
            { includeMetadataChanges: true },
            (querySnapshot) => {
                const changes = getUserDataSnapshotChanges(querySnapshot)
                const { data, changeType } = changes

                data.config && handleConfigChanges(data.config, changeType, this.loadedData)
                data.products && handleUserProductsChanges(data.products, changeType, this.loadedData)
                data.personalData && handlePersonalDataChanges(data.personalData, this.loadedData)
                data.recipes && handleUserRecipesChanges(data.recipes, changeType, this.loadedData)

                if (data.config?.admin) {
                    this.listenAdminData()
                }
            }
        )
    }

    public listenBalanceRecords (timestamp: BalanceRecordTimestamp):void {
        const { year, month } = timestamp
        const listenerID = `${year}${month}`
        const balanceRecordsPath = createURLPath(
            FIRESTORE_COLLECTIONS.users, this.user.id, FIRESTORE_COLLECTIONS.balanceRecords, year.toString(), month.toString()
        )
        const balanceRecords = collection(FirebaseFS, balanceRecordsPath)

        if (isUndefined(this.balanceRecordsListeners[listenerID])) {
            this.balanceRecordsListeners[listenerID] = onSnapshot(
                balanceRecords,
                { includeMetadataChanges: true },
                (querySnapshot) => {
                    const changes = getBalanceRecordsSnapshotChanges(querySnapshot)

                    handleBalanceRecordsChanges(changes, this.loadedData)
                }
            )
        }
    }

    private listenRecipes (): void {
        const recipesPath = createURLPath(FIRESTORE_COLLECTIONS.recipes)
        const recipes = collection(FirebaseFS, recipesPath)

        this.unListenRecipes = onSnapshot(
            recipes,
            { includeMetadataChanges: true },
            (querySnapshot) => {
                handleRecipesChanges(querySnapshot)
            }
        )
    }

    private listenFirestore (): void {
        this.listening = true

        this.listenDataLoadReadinessStatus()
        this.listenUserData()
        this.listenBalanceRecords(this.todayTimestamp)
        this.loadProducts()
        this.loadRecipes()
    }

    private unListenBalanceRecord (listenerId: string): void {
        this.balanceRecordsListeners[listenerId]()

        delete this.balanceRecordsListeners[listenerId]
    }

    private unListenBalanceRecords (): void {
        const balanceRecordsListeners = this.balanceRecordsListeners

        Object.keys(balanceRecordsListeners).forEach((listenerId) => this.unListenBalanceRecord(listenerId))
    }

    public unListenFirestore (): void {
        this.listening = false
        this.user = getInitialUserAuthentication()
        this.loadedData = getLoadedDataInitialValues()

        this.unListenUserData()
        this.unListenBalanceRecords()
        this.unListenRecipes()
    }

    private authenticateUser (user: User): void {
        const userAuthentication = useUserAuthentication()
        const userConfig = useUserConfig()
        const loadingScreenInterface = useLoadingScreenInterface()

        userConfig.$patch({
            anonymous: user.isAnonymous
        })

        userAuthentication.$patch({
            authenticated: true,
            initialised: true,
            email: user.email || ``,
            id: user.uid,
            creationTimestamp: getUserCreationTimestamp(user.metadata.creationTime)
        })

        loadingScreenInterface.visibility = true
    }

    private initialiseUser (): void {
        const userAuthentication = useUserAuthentication()
        const authenticationData: UserAuthentication = {
            ...getInitialUserAuthentication(),
            initialised: true
        }

        userAuthentication.$patch({
            ...authenticationData
        })

        loadAppropriateInitialRoute()
    }

    private onAuthStateChanged (user: User | null): void {
        if (user) {
            this.authenticateUser(user)
        } else {
            this.initialiseUser()
        }
    }

    public init () {
        const userAuthentication = useUserAuthentication()

        onAuthStateChanged(
            FirebaseAuth,
            (user) => this.onAuthStateChanged(user)
        )

        userAuthentication.$subscribe((mutation, state) => {
            this.user = state

            if (!this.listening && state.id) {
                this.listenFirestore()
            } else if (this.listening && !state.id) {
                this.unListenFirestore()
            }
        })
    }
}

export const UserDataManager = new UserDataManagerClass()
