import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-197ee1cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full relative rounded-inherit" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center bg-imagePlaceholder rounded-13 absolute"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex shrink-0", {
             "relative": _ctx.showShadow,
         }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("img", {
        class: _normalizeClass(["w-full h-full object-contain select-none z-10 absolute rounded-inherit", {
                    [_ctx.imageClass]: _ctx.imageClass,
                    "fade-in": _ctx.imageLoaded,
                 }]),
        src: _ctx.src,
        alt: _ctx.alt,
        ref: "imageᴱ"
      }, null, 10, _hoisted_2), [
        [_vShow, _ctx.imageLoaded]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["uppercase heading-4 text-inactive font-medium absolute min-w-full h-full bg-imagePlaceholder rounded-13 flex justify-center items-center", {
                     [_ctx.placeholderClass]: _ctx.placeholderClass,
                     "fade-out": !_ctx.showPlaceholder
                 }])
      }, _toDisplayString(_ctx.imagePlaceholder), 3),
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              icon: _ctx.ICONS.exclamationMark,
              class: "fill-inactive"
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showShadow && _ctx.imageLoaded)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "w-full h-full absolute inset-0",
          src: _ctx.shadowSrc,
          alt: ""
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}