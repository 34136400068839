/* Logic */
import { createValuesSameAsKeys } from '@/library/scripts/utils/object'

export const GLOBAL_EVENTS = {
    authenticateAuthenticateWithEmail: `authenticate:authenticateWithEmail`,
    authenticateHideAuthenticateActions: `authenticate:hideAuthenticateActions`,
    authenticateToggleAuthenticateActions: `authenticate:toggleAuthenticateActions`,
    balanceChangeBalanceRecord: `balance:changeBalanceRecord`,
    baseBreakPointChange: `base:breakPointChange`,
    foodListSort: `foodList:sort`,
    formButtonFinishProcessing: `formButton:finishProcessing`,
    navigationHideMealsPlanShortcut: `navigation:hideMealsPlanShortcut`,
    notificationsShowMessage: `notifications:showMessage`,
    numberInputResize: `numberInput:resize`,
    selectedItemAddItem: `selectedItem:addItem`,
    settingsHideUserActions: `settings:hideActionForm`,
    settingsSignOut: `settings:signOut`,
    settingsToggleActions: `settings:toggleUserActions`
}

export const COMPONENT_EVENTS = createValuesSameAsKeys({
    addProduct: ``,
    addRecipe: ``,
    categoryChange: ``,
    deleteProduct: ``,
    deleteRecipe: ``,
    goBack: ``,
    hideProduct: ``,
    hideRecipe: ``,
    reloadRecipe: ``,
    showProduct: ``,
    showRecipe: ``,
    toggleMealPlanPicker: ``,
    updateProduct: ``,
    updateProductNutrients: ``,
    updateRecipe: ``
})

export const NATIVE_EVENTS = createValuesSameAsKeys({
    change: ``,
    blur: ``
})
