/* Logic */
import {
    defineComponent,
    ref
} from 'vue'
import {
    lockScroll,
    unlockScroll
} from '@/library/scripts/utils/scrollLock'
import { storeToRefs } from 'pinia'
import { useConfirmationOverlayInterface } from '@/store/interface/confirmationOverlay'

/* Config */
import { CONFIG } from './confirmationOverlay.config'

/* Components */
import Heading2 from '@/components/text/headings/heading2.model.vue'
import Text1 from '@/components/text/text/text1.model.vue'
import DefaultButton from '@/components/buttons/defaultButton/defaultButton.model.vue'
import WidgetContainer from '@/components/containers/widgetContainer/widgetContainer.model.vue'

export default defineComponent({
    components: {
        Heading2,
        Text1,
        DefaultButton,
        WidgetContainer
    },
    setup () {
        const form = ref<HTMLElement | null>(null)
        const confirmationOverlay = useConfirmationOverlayInterface()
        const {
            options,
            visibility,
            confirmationCallback
        } = storeToRefs(confirmationOverlay)

        return {
            options,
            confirmationOverlay,
            confirmationCallback,
            form,
            visibility,
        }
    },
    computed: {
        displayedText (): string {
            let text = CONFIG.confirmationText

            if (this.options.text) {
                text = this.options.text
            }

            return text
        }
    },
    methods: {
        onConfirm (): void {
            if (this.confirmationCallback) {
                this.confirmationCallback()
            }

            this.confirmationOverlay.hide()
        },
        onDecline (): void {
            this.confirmationOverlay.hide()
        }
    },
    watch: {
        visibility (visibility) {
            if (this.form) {
                if (visibility) {
                    lockScroll(this.form);
                } else {
                    unlockScroll(this.form);
                }
            }
        }
    }
})
