import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["inline-flex items-center place-content-center", {
                    "h-39 min-w-39 rounded-action bg-action shadow-actionDefault": _ctx.dropShadow,
                    "h-34 w-34": !_ctx.dropShadow,
                    "ml-actionAlign": _ctx.alignLeft,
                    "mr-actionAlign": _ctx.alignRight,
                    "group-icon hover:text-hover": !_ctx.disabled
                }]),
    type: _ctx.tag === `button` ? `button` : false
  }, [
    _createVNode(_component_Icon, {
      icon: _ctx.icon,
      class: _normalizeClass(_ctx.iconClass)
    }, null, 8, ["icon", "class"])
  ], 10, _hoisted_1))
}