/* Logic */
import {
    defineComponent,
    ref
} from 'vue'

/* Config */
import {
    SERVICE_WORKER_EVENTS,
    SERVICE_WORKER_MESSAGES
} from '@/serviceWorker/serviceWorker.config'
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { NOTIFICATIONS } from '@/library/scripts/values/notifications'
import { CONFIG } from './versionUpdater.config'

export default defineComponent({
    setup () {
        const refreshing = ref(false)
        const visibility = ref(false)
        const registration = ref<ServiceWorkerRegistration | undefined>(undefined)

        return {
            refreshing,
            visibility,
            registration
        }
    },
    methods: {
        updateApp (): void {
            this.$events.emit(GLOBAL_EVENTS.notificationsShowMessage, NOTIFICATIONS.applicationUpdate)

            setTimeout(() => {
                this.registration?.waiting?.postMessage(SERVICE_WORKER_MESSAGES.skipWaiting)
            }, CONFIG.updateDelay)
        },
        addListeners () {
            document.addEventListener(SERVICE_WORKER_EVENTS.updated, ((event: CustomEvent) => {
                this.registration = event.detail /* store the ServiceWorkerRegistration instance for later use. */
                this.updateApp()
            }) as EventListener,
            { once: true })
        }
    },
    mounted (): void {
        this.addListeners()
    },
    created () {
        /*
      Refresh all open app tabs when a new service worker is installed */
        navigator.serviceWorker.addEventListener(`controllerchange`, () => {
            if (!this.refreshing) {
                this.refreshing = true
                window.location.reload()
            }
        })
    }
})
