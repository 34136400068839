/* Logic */
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import {
    getFirestore,
    enableIndexedDbPersistence
} from 'firebase/firestore'

const FIREBASE_CONFIG = {
    apiKey: `AIzaSyBr4ot6rFOZOAnR2_o0GFretoUhCYGlSfY`,
    authDomain: `app.lifebalance.earth`,
    databaseURL: `https://nutrition-46923.firebaseio.com`,
    projectId: `nutrition-46923`,
    storageBucket: `nutrition-46923.appspot.com`,
    messagingSenderId: `179285367964`,
    appId: `1:179285367964:web:6a9faa2c7bafc59b`
}

/*
Get a Firestore instance and database */
export const FirebaseApp = initializeApp(FIREBASE_CONFIG)
export const FirebaseFS = getFirestore(FirebaseApp)
export const FirebaseStorage = getStorage(FirebaseApp)
export const FirebaseAuth = getAuth(FirebaseApp)

/*
Enable offline support */
/* eslint-disable no-console */
export function enableOfflinePersistence () {
    enableIndexedDbPersistence(FirebaseFS)
        .then(() => {
            console.log(`Enabled offline persistence`)
        })
        .catch(function (err) {
            if (err.code === `failed-precondition`) {
                console.log(`failed-precondition`)
                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                // ...
            } else if (err.code === `unimplemented`) {
                console.log(`unimplemented`)
                // The current browser does not support all of the
                // features required to enable persistence
                // ...
            }
        })
}
/* eslint-enable no-console */

enableOfflinePersistence()
