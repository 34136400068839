/* Logic */
import { defineStore } from 'pinia'
import {
    getLastStoredBalanceRecordTimestamp,
    updateBalanceRecordNutrientsAndCompleteness
} from '@/library/scripts/utils/balanceRecord'
import { useProductsStore } from '@/store/products'
import { useRecipesStore } from '@/store/recipes'

/* Types */
import {
    addRecordsOptions,
    BalanceRecords,
    UpdateRecordOptions,
    UpdateRecordWithItemOptions
} from './types'
import {
    BalanceRecord,
    BalanceRecordCompleteness,
    BalanceRecordNutrients,
    BalanceRecordTimestamp
} from '@/library/scripts/types/balanceRecord'
import { cloneDeep } from '@/library/scripts/utils/object'

const records: BalanceRecords = {}
const personalisedNutrients = {} as BalanceRecordNutrients
const personalisedCompleteness = {} as BalanceRecordCompleteness

export const useUserBalanceRecords = defineStore(`user/balanceRecords`, {
    state: () => {
        return {
            records: cloneDeep(records),
            personalisedNutrients: cloneDeep(personalisedNutrients),
            personalisedCompleteness: cloneDeep(personalisedCompleteness)
        }
    },
    getters: {
        getRecord: (state) => (recordTimestamp: BalanceRecordTimestamp): BalanceRecord => {
            const { year, month, day } = recordTimestamp
            let balanceRecord = {} as BalanceRecord

            if (state.records[year] && state.records[year][month]) {
                balanceRecord = state.records[year][month][day]
            }

            return balanceRecord
        },
        getLastStoredBalanceRecordTimestamp: (state): BalanceRecordTimestamp => {
            return getLastStoredBalanceRecordTimestamp(state.records)
        }
    },
    actions: {
        addRecords (options: addRecordsOptions) {
            const { records } = options
            const { year, month } = options.timestamp

            if (!this.records[year]) {
                this.records[year] = {}
            }

            this.records[year][month] = records
        },
        updateRecord (options: UpdateRecordOptions) {
            const { year, month, day } = options.timestamp

            this.records[year][month][day] = options.record
        },
        updateRecordWithProduct (options: UpdateRecordWithItemOptions) {
            const { year, month, day } = options.timestamp
            const productsStore = useProductsStore()
            const balanceRecord = this.records[year][month][day]
            const {
                id,
                quantityDifference,
                mealPlanName,
                mealPlan
            } = options.difference
            const product = productsStore.getProduct(id)

            if (product) {
                updateBalanceRecordNutrientsAndCompleteness(
                    balanceRecord,
                    product.nutrients,
                    quantityDifference
                )
            }

            balanceRecord.mealsPlan[mealPlanName] = mealPlan
        },
        updateRecordWithRecipe (options: UpdateRecordWithItemOptions) {
            const { year, month, day } = options.timestamp
            const recipesStore = useRecipesStore()
            const balanceRecord = this.records[year][month][day]
            const {
                id,
                quantityDifference,
                mealPlanName,
                mealPlan
            } = options.difference
            const recipe = recipesStore.getRecipe(id)

            if (recipe) {
                const { nutrients } = recipe

                updateBalanceRecordNutrientsAndCompleteness(
                    balanceRecord,
                    nutrients,
                    quantityDifference
                )

                balanceRecord.mealsPlan[mealPlanName] = mealPlan
            }
        }
    }
})
