/* Logic */
import { defineStore } from 'pinia'
import { InterfaceRecipeComponents } from '@/store/interface/recipes/types'

const recipeComponents: InterfaceRecipeComponents = {
    ingredients: true,
    method: false,
    information: false
}

export const useRecipesInterface = defineStore(`interface/recipes`, {
    state: () => ({
        components: {
            recipesList: {
                visibility: true
            },
            recipe: {
                id: ``,
                visibility: false,
                components: recipeComponents
            }
        }
    }),
    actions: {
        hideRecipe (): void {
            this.$patch({
                components: {
                    recipe: {
                        id: ``,
                        visibility: false
                    }
                }
            })
        },
        showRecipe (id: string): void {
            this.$patch({
                components: {
                    recipe: {
                        id,
                        visibility: true
                    }
                }
            })
        }
    }
})
