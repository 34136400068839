export const NOTIFICATIONS = {
    accountDeleted: `Your account has been successfully deleted`,
    accountExists: `The credentials you have provided are already associated with different authentication method. Please use original authentication method`,
    applicationUpdate: `The new application update is available, restarting now`,
    authenticated: `You have successfully authenticated`,
    authenticationEmailSent: `Authentication email has been successfully sent. Please check your inbox`,
    changeSaved: `Your change has been successfully saved`,
    emailChanged: `Your email address has been successfully changed`,
    emailInvalid: `Provided email does not match the authentication email address`,
    emailWasSent: `Verification email was successfully sent`,
    invalidActionCode: `Authentication link is invalid. This can happen if it's expired, has already been used or is malformed`,
    loadingError: `There was an error while loading the application, restarting now`,
    genericError: `An error occurred during the last action. Please try again later`,
    noConnection: `No internet connection, please try later`,
    offlineSupportEnabled: `Offline support have been successfully enabled`,
    passwordChanged: `Your password is successfully changed`,
    personalised: `Application has been successfully personalised`,
    processingIssue: `There was an issue processing your request. Please try again later`,
    productAdded: `Product has been successfully added`,
    productDeleted: `Product has been successfully deleted`,
    productUpdated: `Product has been successfully updated`,
    tooManyRequest: `We have already performed your request. Please try again later`,
    recipeUpdated: `Recipe has been successfully updated`,
    recipeDeleted: `Recipe has been successfully deleted`,
    noPermissions: `You do not have permissions to perform this action`,
    containsPersonalProducts: `This recipe contains personal product(s) and can't be shared with others`,
    containsPersonalRecipes: `This recipe contains personal recipe(s) and can't be shared with others`,
}
