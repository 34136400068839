/* Logic */
import { defineStore } from 'pinia'
import { useUserRecipes } from '@/store/user'

/* Types */
import {
    Recipe,
    RecipeAuthor,
    RecipeAuthors,
    Recipes
} from '@/library/scripts/types/recipe'

export const useRecipesStore = defineStore(`recipes`, {
    state: () => ({
        recipes: {} as Recipes,
        editableRecipe: {} as Recipe,
        authors: {} as RecipeAuthors
    }),
    getters: {
        allRecipes(state) {
            const userRecipes = useUserRecipes()

            return {
                ...state.recipes,
                ...userRecipes.recipes
            }
        },
        recipesQuantity(state) {
            return Object.keys(state.recipes).length
        },
        allRecipesQuantity(state) {
            const userRecipes = useUserRecipes()

            return Object.keys(state.recipes).length + userRecipes.recipesQuantity
        },
        getRecipe() {
            return (id: string): Recipe | undefined => {
                return this.allRecipes[id]
            }
        },
        getRecipeAuthor() {
            return (id: string): RecipeAuthor | undefined => {
                return this.authors[id]
            }
        }
    }
})
