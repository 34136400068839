/* Logic */
import {
    defineComponent,
    ref
} from 'vue'
import { useInterface } from '@/store/interface'

/* Config */
import { ROUTES } from '@/router/router.config'
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'
import { CONTACT_INFORMATION } from '@/library/scripts/values/contactInformation'
import { ICONS } from '@/components/icon/icon.config'
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'

/* Components */
import IconButton from '@/components/buttons/iconButton/iconButton.model.vue'
import NavigationContainer from '@/components/containers/navigationContainer.model.vue'
import DefaultButton from '@/components/buttons/defaultButton/defaultButton.model.vue'

export default defineComponent({
    components: {
        IconButton,
        NavigationContainer,
        DefaultButton
    },
    setup () {
        const isXSViewports = ref(true)
        const interfaceStore = useInterface()

        return {
            interfaceStore,
            isXSViewports,
            ROUTES,
            CONTACT_INFORMATION,
            ICONS
        }
    },
    computed: {
        isAuthenticateRoute (): boolean {
            return this.$route?.path === ROUTES.authenticate.path
        },
        isInvertedFooterLinks (): boolean {
            return !this.isXSViewports && this.isAuthenticateRoute
        },
        visibility (): boolean {
            const notAllowedInRoutes = [
                ROUTES.privacyPolicy,
                ROUTES.termsOfService,
                ROUTES.initial
            ]
            const bottomNavigationRoutes = [
                ROUTES.balance,
                ROUTES.profile,
                ROUTES.recipes,
                ROUTES.recipeEditor
            ]
            const currentRoute = this.$route.path
            let visibility

            // 1. Check if footer is allowed
            visibility = notAllowedInRoutes.every((route) => {
                return route.path !== currentRoute
            })

            // 2. Check if footer is allowed in Balance route
            if (visibility && this.interfaceStore.breakPoint < BREAKPOINTS.md) {
                visibility = bottomNavigationRoutes.every((route) => {
                    return route.path !== currentRoute
                })
            }

            return visibility
        }
    },
    methods: {
        updateLayoutView (breakPoint: number): void {
            switch (breakPoint) {
                case BREAKPOINTS.xsm:
                    this.isXSViewports = true
                    break
                default:
                    this.isXSViewports = false
            }
        },
        addListeners () {
            this.$events.on(GLOBAL_EVENTS.baseBreakPointChange, this.updateLayoutView)
        }
    },
    mounted () {
        this.updateLayoutView(this.interfaceStore.breakPoint)
        this.addListeners()
    }
})
