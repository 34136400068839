/* Logic */
import { defineStore } from 'pinia'
import { useUserProductsStore } from '@/store/user/products'

/* Types */
import { ProductsState } from './types'
import { Product } from '@/library/scripts/types/product'

const products: ProductsState = {}

export const useProductsStore = defineStore(`products`, {
    state: () => ({
        products
    }),
    getters: {
        allProducts (state) {
            const userProducts = useUserProductsStore()

            return {
                ...state.products,
                ...userProducts.products
            }
        },
        productsQuantity (state) {
            return Object.keys(state.products).length
        },
        allProductsQuantity (state) {
            const userProducts = useUserProductsStore()

            return Object.keys(state.products).length + userProducts.productsQuantity
        },
        getProduct () {
            return (id: string): Product | undefined => {
                return this.allProducts[id]
            }
        }
    }
})
