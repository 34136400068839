import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading4 = _resolveComponent("Heading4")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed w-full h-full inset-0 items-center justify-center bg-widget z-loadingScreen", {
             "flex": _ctx.visibility,
             "hidden": !_ctx.visibility
         }])
  }, [
    _createElementVNode("div", {
      class: "absolute h-5 w-full inset-x-0 top-0 shadow-widgetAngleDown bg-loadingBar origin-left duration-loadingBar",
      style: _normalizeStyle(_ctx.loadingBarStyle)
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", { innerHTML: _ctx.loadingScreenIconHTML }, null, 8, _hoisted_2),
      _createVNode(_component_Heading4, {
        capitalize: false,
        class: "dots-animation"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loadingText), 1)
        ]),
        _: 1
      })
    ])
  ], 2))
}