/* Logic */
import { defineComponent } from 'vue'
import { useInterface } from '@/store/interface'

/* Config */
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'
import { ROUTES } from '@/router/router.config'

/* Components */
import BottomNavigation from './components/bottomNavigation/bottomNavigation.model.vue'
import NavigationBackButton from './components/backButton/backButton.model.vue'
import TopNavigation from './components/topNavigation/topNavigation.model.vue'

export default defineComponent({
    components: {
        NavigationBackButton,
        BottomNavigation,
        TopNavigation
    },
    setup () {
        const interfaceStore = useInterface()

        return {
            interfaceStore
        }
    },
    computed: {
        topAndBottomNavigationVisibility (): boolean {
            const notAllowedInRoutes = [
                ROUTES.authenticate,
                ROUTES.initial,
                ROUTES.intro,
                ROUTES.personalise,
                ROUTES.privacyPolicy,
                ROUTES.settings,
                ROUTES.termsOfService
            ]
            const currentRoute = this.$route.path

            return notAllowedInRoutes.every((route) => {
                return route.path !== currentRoute
            })
        },
        bottomNavigationVisibility (): boolean {
            let visibility = true

            if (this.topAndBottomNavigationVisibility && this.interfaceStore.breakPoint >= BREAKPOINTS.md) {
                visibility = false
            }

            return visibility
        },
        backButtonVisibility (): boolean {
            const notAllowedInRoutes = [
                ROUTES.authenticate,
                ROUTES.personalise,
                ROUTES.initial,
                ROUTES.intro,
                ROUTES.settings
            ]
            const currentRoute = this.$route.path
            const nowAllowed = notAllowedInRoutes.every((route) => {
                return route.path !== currentRoute
            })

            return !this.topAndBottomNavigationVisibility && nowAllowed
        }
    }
})
