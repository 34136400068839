/* Logic */
import { isPWA } from '@/library/scripts/utils/pwa'
import { defineComponent } from 'vue'
import { useInterface } from '@/store/interface'
import {
    lockScroll,
    unlockScroll,
    clearAllScrollLocks
} from '@/library/scripts/utils/scrollLock'

/* Config */
import { CONFIG } from './swipeableWrapper.config'
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'

export default defineComponent({
    props: {
        touchEndCallback: {
            type: Function,
            default: () => {}
        },
        isSwipeable: {
            type: Boolean,
            default: true
        },
        customSpacing: {
            type: Boolean,
            default: false
        },
        customShadow: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        const interfaceStore = useInterface()

        return {
            interfaceStore
        }
    },
    data () {
        return {
            initialX: 0,
            isPWA: isPWA(),
            isSwiping: false,
            movedX: 0,
            scrollLocked: false,
            componentClasses: ``
        }
    },
    computed: {
        currentX (): number {
            return Math.abs(this.movedX + Math.abs(CONFIG.moveXThreshold))
        },
        swipeProgress (): number {
            return this.currentX / window.innerWidth / 100
        },
        hideThreshold (): number {
            const usableScreenArea = window.innerWidth

            return usableScreenArea * CONFIG.hideThreshold
        },
        swipeableStyle () {
            let xValue = 0

            if (this.movedX <= CONFIG.moveXThreshold) {
                xValue = this.currentX * CONFIG.wrapperMovementMultiplier
            }

            return {
                transform: `translate3d(${xValue}px, 0, 0)`
            }
        }
    },
    methods: {
        onTouchMove (event: TouchEvent): void {
            this.movedX = this.initialX - event.touches[0].pageX

            if (this.movedX <= CONFIG.moveXThreshold) {
                event.preventDefault()

                if (!this.isSwiping) {
                    this.isSwiping = true
                }
            }
        },
        onTouchStart (event: TouchEvent): void {
            this.initialX = event.touches[0].pageX
        },
        onTouchEnd (): void {
            if (this.currentX >= this.hideThreshold && this.initialX <= this.hideThreshold) {
                const $el = this.$el as HTMLDivElement

                this.initialX = 0
                this.isSwiping = false

                this.$el.animate([
                    { transform: this.swipeableStyle.transform },
                    { transform: `translate3d(${$el.offsetWidth / 1.62}px, 0, 0)` }
                ], {
                    duration: CONFIG.animationDuration
                })

                setTimeout(() => {
                    unlockScroll(this.$el)
                    this.touchEndCallback()
                }, CONFIG.animationDuration)
            } else {
                this.initialX = 0
                this.isSwiping = false

                this.$el.animate([
                    { transform: this.swipeableStyle.transform },
                    { transform: `translate3d(0, 0, 0)` }
                ], {
                    duration: CONFIG.animationDuration
                })
            }
        },
        addComponentSpacingClasses (): void {
            if (!this.customSpacing) {
                this.componentClasses += CONFIG.defaultSpacingClasses
            }
        },
        updateLayoutView (
            breakPoint: number
        ): void {
            const { $el } = this
            // const options = {
            //     passive: true
            // }

            switch (breakPoint) {
                case BREAKPOINTS.xsm:
                    this.scrollLocked = true

                    lockScroll($el)
                    this.componentClasses = CONFIG.defaultClasses

                    if (this.isPWA) {
                        // $el.addEventListener(`touchstart`, this.onTouchStart, options)
                        // $el.addEventListener(`touchmove`, this.onTouchMove, options)
                        // $el.addEventListener(`touchend`, this.onTouchEnd, options)
                    }
                    break
                default:
                    if (this.scrollLocked) {
                        if (this.isPWA) {
                            // $el.removeEventListener(`touchstart`, this.onTouchStart)
                            // $el.removeEventListener(`touchmove`, this.onTouchMove)
                            // $el.removeEventListener(`touchend`, this.onTouchEnd)
                        }

                        this.componentClasses = ``
                        clearAllScrollLocks()
                    }
            }

            this.addComponentSpacingClasses()
        },
        addListeners (): void {
            this.$events.on(GLOBAL_EVENTS.baseBreakPointChange, this.updateLayoutView)
        },
        removeListeners (): void {
            this.$events.off(GLOBAL_EVENTS.baseBreakPointChange, this.updateLayoutView)
        }
    },
    mounted (): void {
        if (this.isSwipeable) {
            const { breakPoint } = useInterface()

            this.addListeners()
            this.updateLayoutView(breakPoint)
        } else {
            this.addComponentSpacingClasses()
        }
    },
    beforeUnmount (): void {
        if (this.isSwipeable) {
            unlockScroll(this.$el)
            this.removeListeners()
        }
    }
})
