import { useUserRecipes } from './recipes'
import { useUserConfig } from './config'
import { useUserAuthentication } from './authentication'
import { useUserPersonalData } from './personalData'
import { useUserLoadedData } from './loadedData'
import { useUserProductsStore } from './products'
import { useUserBalanceModel } from './balanceModel'
import { useUserBalanceRecords } from './balanceRecords'

export { useUserRecipes } from './recipes'
export { useUserConfig } from './config'
export { useUserAuthentication } from './authentication'
export { useUserPersonalData } from './personalData'
export { useUserLoadedData } from './loadedData'
export { useUserProductsStore } from './products'
export { useUserBalanceModel } from './balanceModel'
export { useUserBalanceRecords } from './balanceRecords'

export function resetUserStore () {
    const userRecipes = useUserRecipes()
    const userConfig = useUserConfig()
    const userAuthentication = useUserAuthentication()
    const userPersonalData = useUserPersonalData()
    const userLoadedData = useUserLoadedData()
    const userProductsStore = useUserProductsStore()
    const userBalanceModel = useUserBalanceModel()
    const userBalanceRecords = useUserBalanceRecords()
    const userStores = [
        userRecipes,
        userConfig,
        userAuthentication,
        userPersonalData,
        userLoadedData,
        userProductsStore,
        userBalanceModel,
        userBalanceRecords
    ]

    userStores.forEach((store) => {
        store.$reset()
    })
}
