import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cccc350a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items-end h-full w-full flex fixed left-0 right-0 bottom-0 z-0" }
const _hoisted_2 = { class: "bg-widget w-full z-20 overflow-y-auto max-h-screen" }
const _hoisted_3 = {
  key: 0,
  class: "pb-bottomNavigationHeight px-widget mt-39-9 mb-34-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FoldedMealPlan = _resolveComponent("FoldedMealPlan")!

  return (_openBlock(), _createBlock(_Transition, { duration: _ctx.transitionDuration }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: "fixed inset-0 bg-overlay h-double z-10 -translate-y-2/4",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideMealsPlanShortcut && _ctx.hideMealsPlanShortcut(...args)))
            }, null, 512), [
              [_vShow, _ctx.visibility]
            ])
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "slide" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              (_ctx.hasLoadedBalanceRecord)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MEALS_PLAN_ORDER, (mealPlanName, index) => {
                      return (_openBlock(), _createBlock(_component_FoldedMealPlan, {
                        balanceRecord: _ctx.balanceRecord,
                        mealPlanName: mealPlanName,
                        key: index,
                        onClick: ($event: any) => (_ctx.showFoodList(mealPlanName))
                      }, null, 8, ["balanceRecord", "mealPlanName", "onClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.visibility]
            ])
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, _ctx.visibility]
      ])
    ]),
    _: 1
  }, 8, ["duration"]))
}