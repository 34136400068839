/* Config */
import {
    CONTAINER_CATEGORIES,
    NUTRIENTS_LIST_BY_CONTAINER_CATEGORY,
    LIPIDS_PROGRESS_NUTRIENTS
} from '@/library/scripts/values/nutrients'

export const BALANCE_RECORD_PROGRESS_CONTAINER_CATEGORIES = [
    CONTAINER_CATEGORIES.vitamins,
    CONTAINER_CATEGORIES.minerals
]

export const BALANCE_RECORD_CONTAINER_CATEGORIES = {
    nutrients: [
        CONTAINER_CATEGORIES.carbohydrates,
        CONTAINER_CATEGORIES.lipids,
        CONTAINER_CATEGORIES.proteins,
        CONTAINER_CATEGORIES.vitamins,
        CONTAINER_CATEGORIES.minerals
    ],
    water: CONTAINER_CATEGORIES.water,
    energy: CONTAINER_CATEGORIES.energy
}

export const BALANCE_RECORD_NUTRIENT_SECTIONS = [
    CONTAINER_CATEGORIES.carbohydrates,
    CONTAINER_CATEGORIES.lipids,
    CONTAINER_CATEGORIES.proteins,
    ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.vitamins],
    ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.minerals],
    ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.proteins],
    ...NUTRIENTS_LIST_BY_CONTAINER_CATEGORY[CONTAINER_CATEGORIES.carbohydrates],
    ...LIPIDS_PROGRESS_NUTRIENTS
]
