/* Logic */
import { createApp } from 'vue'
import mitt from 'mitt'
import '@/serviceWorker/registerServiceWorker'
import Router from '@/router'
import { createPinia } from 'pinia'
import { MediaQueryListListener } from '@/library/scripts/plugins/mediaQueryListListener'
import { UserDataManager } from '@/library/scripts/plugins/userDataManager/userDataManager.module'
import { UserConnectionStatus } from '@/library/scripts/plugins/userConnectionStatus/userConnectionStatus.module'
import '@/library/styles/global/tailwind/index.css'
// @ts-ignore
import { dragscrollNext } from 'vue-dragscroll'

import * as Sentry from '@sentry/vue'
import {
    BrowserTracing, Integrations
} from '@sentry/tracing'

/* Components */
import Base from '@/components/base/base.model.vue'

/* Config */
import { GLOBAL_SELECTORS } from '@/library/scripts/values/selectors'

/* Event Bus */
export const $events = mitt()

const Store = createPinia()
const App = createApp(Base)

App.config.globalProperties.$events = $events

App.directive(`dragscroll`, dragscrollNext)
App.use(Store)
App.use(Router)

// Has dependencies on Events' bus and Pinia Store and for that reason initialised after
MediaQueryListListener.init()
UserDataManager.init()
UserConnectionStatus.init()

/*
* Error Tracking
**/
if (process.env.NODE_ENV === `production`) {
    Sentry.init({
        app: App,
        dsn: `https://d7f472d3a65149369f6b5a16039e2ad2@o809870.ingest.sentry.io/5804652`,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
                tracePropagationTargets: [`lifebalance.earth`, /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}

Router.isReady().then(() => {
    App.mount(GLOBAL_SELECTORS.base)
})
