/* Logic */
import {
    defineComponent,
    PropType
} from 'vue'
import { useBalanceInterface } from '@/store/interface'
import { getTodayTimestamp } from '@/library/scripts/plugins/userDataManager/userDataManager.utils'
import { useUserAuthentication } from '@/store/user'
import { isEmptyObject } from '@/library/scripts/utils/object'

/* Types */
import {
    BalanceRecord,
    BalanceRecordMealPlan,
    BalanceRecordMealPlanProducts, BalanceRecordMealPlanRecipes,
    BalanceRecordTimestamp
} from '@/library/scripts/types/balanceRecord'

export default defineComponent({
    setup () {
        const balanceInterface = useBalanceInterface()
        const userAuthentication = useUserAuthentication()

        return {
            userAuthentication,
            balanceInterface
        }
    },
    props: {
        balanceRecord: {
            type: Object as PropType<BalanceRecord>,
            required: true
        },
        mealPlanName: {
            type: String,
            required: true
        },
        balanceRecordTimestamp: {
            type: Object as PropType<BalanceRecordTimestamp>,
            default: getTodayTimestamp()
        }
    },
    computed: {
        hasMealPlanProducts (): boolean {
            return !isEmptyObject(this.mealPlanProducts)
        },
        hasMealPlanRecipes (): boolean {
            return !isEmptyObject(this.mealPlanRecipes)
        },
        hasItems (): boolean {
            return this.hasMealPlanProducts || this.hasMealPlanRecipes
        },
        mealPlan (): BalanceRecordMealPlan {
            return this.balanceRecord.mealsPlan[this.mealPlanName]
        },
        mealPlanProducts (): BalanceRecordMealPlanProducts {
            return this.mealPlan.products
        },
        mealPlanRecipes (): BalanceRecordMealPlanRecipes {
            return this.mealPlan.recipes
        }
    },
    methods: {
        showFoodList (): void {
            this.balanceInterface.showFoodList(this.mealPlanName)
        },
        showProduct (id: string): void {
            this.showFoodList()
            this.balanceInterface.showProduct(id)
        },
        showRecipe (id: string): void {
            this.showFoodList()
            this.balanceInterface.showRecipe(id)
        }
    }
})
