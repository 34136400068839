/* Logic */
import { defineStore } from 'pinia'
import { getTodayTimestamp } from '@/library/scripts/plugins/userDataManager/userDataManager.utils'

/* Types */
import { InterfaceRecipeComponents } from '@/store/interface/recipes/types'

export const getInitialInterfaceBalance = () => {
    return {
        components: {
            detailedStats: {
                visibility: false
            },
            mealsPlan: {
                visibility: true
            },
            mealPlanPicker: {
                visibility: false
            },
            foodList: {
                visibility: false,
                mealPlan: ``
            },
            product: {
                visibility: false,
                id: ``
            },
            recipe: {
                id: ``,
                visibility: false,
                components: {
                    ingredients: true,
                    method: false,
                    information: false
                } as InterfaceRecipeComponents
            }
        },
        loadedRecordTimestamp: getTodayTimestamp()
    }
}

export const useBalanceInterface = defineStore(`interface/balance`, {
    state: () => ({
        ...getInitialInterfaceBalance()
    }),
    actions: {
        selectMealPlan (mealPlan: string, hideSelectedItem = true): void {
            this.components.foodList.mealPlan = mealPlan

            if (hideSelectedItem) {
                this.hideSelectedItem()
            }
        },
        showProduct (id: string): void {
            if (id !== this.components.product.id) {
                this.$patch({
                    components: {
                        product: {
                            visibility: true,
                            id
                        }
                    }
                })
                this.hideRecipe()
            }
        },
        showRecipe (id: string): void {
            if (id !== this.components.recipe.id) {
                this.$patch({
                    components: {
                        recipe: {
                            id,
                            visibility: true
                        }
                    }
                })
                this.hideProduct()
            }
        },
        showFoodList (mealPlan: string): void {
            this.$patch({
                components: {
                    foodList: {
                        visibility: true,
                        mealPlan
                    }
                }
            })
        },
        hideProduct (): void {
            this.$patch({
                components: {
                    product: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        hideRecipe (): void {
            this.$patch({
                components: {
                    recipe: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        hideFoodList (): void {
            this.$patch({
                components: {
                    foodList: {
                        visibility: false,
                        mealPlan: ``
                    }
                }
            })
        },
        hideSelectedItem (): void {
            this.$patch({
                components: {
                    product: {
                        visibility: false,
                        id: ``
                    },
                    recipe: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        toggleMealPlanPicker (visibility: boolean): void {
            this.components.mealPlanPicker.visibility = visibility
        }
    }
})
