/* Logic */
import { cloneDeep } from '@/library/scripts/utils/object'

/* Config */
import { CONFIG } from '@/library/scripts/plugins/userDataManager/userDataManager.config'
import { BALANCE_MODEL_UNITS } from '@/library/scripts/values/units'

/* Types */
import {
    BalanceModel,
    BalanceModelAMDR,
    BalanceModelSection
} from '@/library/scripts/types/balanceModel'
import { PersonalData } from '@/store/user/personalData/types'
import { NutrientsModelMap } from '@/library/scripts/types/nutrients'
import { ObjectWithNumbers } from '@/library/scripts/types/types'
import { isUndefined } from '@/library/scripts/utils/types'

/*
Data taken from National Academies. Vitamins & Minerals 2006
Physical Activity Rates - page 82
Formula for calculating Estimated Energy Requirement - page 96 */
function _calculateEnergy (
    weight: number,
    height: number,
    age: number,
    gender: string,
    activity: string
): number {
    const menPhysicalActivityRates: ObjectWithNumbers = {
        sedentary: 1,
        lowActive: 1.1,
        active: 1.25,
        veryActive: 1.48
    }
    const womenPhysicalActivityRates: ObjectWithNumbers = {
        sedentary: 1,
        lowActive: 1.2,
        active: 1.27,
        veryActive: 1.45
    }
    let energy = 0

    switch (gender) {
        case CONFIG.genders.male:
            energy = Math.round(
                662 - (9.53 * age) + menPhysicalActivityRates[activity] * ((15.91 * weight) + (539.6 * height / 100))
            )
            break
        case CONFIG.genders.female:
            energy = Math.round(
                354 - (6.91 * age) + womenPhysicalActivityRates[activity] * ((9.36 * weight) + (726 * height / 100))
            )
    }

    return energy
}

function _updateEnergyDependentSections (
    balanceModel: BalanceModel,
    energy: number
): void {
    /*
  Quantity of grams needed to fulfil 1% of Estimated Energy Requirement */
    const energyConversion: { [index: string]: number } = {
        normal: energy / 100 / CONFIG.energyDensity.normal,
        lipids: energy / 100 / CONFIG.energyDensity.lipids
    }
    const sections = [
        ...CONFIG.energyDependent.lipidsSections,
        ...CONFIG.energyDependent.normalSections
    ]

    sections.forEach(sectionName => {
        const section = balanceModel[sectionName]
        const { DRI } = section as BalanceModelSection
        const conversionMultiplier = CONFIG.energyDependent.normalSections.includes(sectionName) ? energyConversion.normal : energyConversion.lipids

        Object.keys(DRI).forEach(DRIName => {
            if (CONFIG.energyDependent.protectedValues.indexOf(DRIName) === -1) {
                if (CONFIG.DRIValues.AMDR === DRIName) {
                    const AMDR = DRI[DRIName] as BalanceModelAMDR

                    Object.keys(AMDR).forEach(AMDRValueName => {
                        const AMDRValue = String(AMDR[AMDRValueName])

                        if (AMDRValue.includes(BALANCE_MODEL_UNITS.percentage)) {
                            AMDR[AMDRValueName] = Math.round(parseInt(AMDRValue) * conversionMultiplier)
                        }
                    })
                } else if (CONFIG.DRIValues.AMDR !== DRIName && CONFIG.energyDependent.lipidsSections.includes(sectionName)) {
                    const DRIValue = String(DRI[DRIName])

                    if (DRIValue.includes(BALANCE_MODEL_UNITS.percentage)) {
                        DRI[DRIName] = Math.round(parseInt(DRIValue) * conversionMultiplier)
                    }
                }
            }
        })
    })
}

function _updateWeightDependentSections (
    balanceModel: BalanceModel,
    weight: number
): void {
    CONFIG.weightDependent.sections.forEach(sectionName => {
        const section = balanceModel[sectionName] as BalanceModelSection

        if (section) {
            const { DRI } = section

            if (DRI) {
                Object.keys(DRI).forEach(DRIName => {
                    if (!CONFIG.weightDependent.protectedValues.includes(DRIName)) {
                        DRI[DRIName] = Math.round(+DRI[DRIName] * weight)
                    }
                })
            }
        }
    })
}

function _deleteNotUsedNutrientSections (
    balanceModel: BalanceModel,
    nutrientsModelMap: NutrientsModelMap
): void {
    Object.keys(balanceModel).forEach(sectionName => {
        if (isUndefined(nutrientsModelMap[sectionName])) {
            delete balanceModel[sectionName]
        }
    })
}

export function createPersonalisedBalanceModel (
    balanceModel: BalanceModel,
    personalData: PersonalData,
    nutrientsModelMap: NutrientsModelMap
): BalanceModel {
    const { age, weight, height, activity, gender } = personalData
    const personalisedBalanceModel = cloneDeep(balanceModel)
    const energySection = personalisedBalanceModel.energy as BalanceModelSection
    const energy = _calculateEnergy(
        weight,
        height,
        age,
        gender,
        activity
    )

    _deleteNotUsedNutrientSections(personalisedBalanceModel, nutrientsModelMap)

    /*
    If personalData energy was updated, additionally update the model with that value */
    energySection.DRI.RDA = energy

    _updateEnergyDependentSections(personalisedBalanceModel, energy)
    _updateWeightDependentSections(personalisedBalanceModel, Number(weight))

    return personalisedBalanceModel
}
