/* Types */
import { ObjectWithStrings } from '@/library/scripts/types/types'
import {
    NumberSelectGenerateOptions,
    NumberSelectOption,
    NumberSelectOptions
} from '@/components/selects/numberSelect/numberSelect.types'
import { TextSelectOptions } from '@/components/selects/textSelect/textSelect.types'

export function createTextSelectOptions (
    options: ObjectWithStrings,
    disabledOptions: ObjectWithStrings = {}
): TextSelectOptions {
    const selectOptions = [] as TextSelectOptions

    Object.keys(options).forEach((option) => {
        selectOptions.push({
            name: options[option],
            value: option
        })
    })

    Object.keys(disabledOptions).forEach((option) => {
        selectOptions.push({
            name: options[option],
            value: option,
            disabled: true
        })
    })

    return selectOptions
}

export function createNumberSelectOptionsWithStep (
    options: NumberSelectGenerateOptions,
    createCustomName?: false | Function,
    roundValue?: boolean
): NumberSelectOptions {
    const { max, step } = options
    let { min } = options
    const selectOptions = []
    let prevName = ``

    do {
        const name = createCustomName ? createCustomName(min) : min

        if (name !== prevName) {
            let value = min * step

            if (roundValue) {
                value = Math.round(value * 100 / 100)
            }

            selectOptions.push({
                name,
                value
            })
        }

        prevName = name
        min++
    } while (min <= max)

    return selectOptions
}

export function createNumberSelectOptionsWithIncrementor (
    options: NumberSelectGenerateOptions,
    createCustomName?: false | Function,
    roundValue?: boolean
): NumberSelectOptions {
    const { min, max, step } = options
    const selectOptions = []
    let prevName = ``

    for (let i = min; i <= max; i += step) {
        const name = createCustomName ? createCustomName(i) : i

        if (name !== prevName) {
            let value = i

            if (roundValue) {
                value = Math.round(value * 100 / 100)
            }

            selectOptions.push({
                name,
                value
            })
        }

        prevName = name
    }

    return selectOptions
}

function _findClosestIndex (
    options: NumberSelectOptions,
    key: number,
    differenceBounds: number = 0
) {
    const isWithinDifferenceBounds = (value: number, key: number): boolean => {
        return Math.abs(value - key) <= differenceBounds
    }
    let start = 0
    let end = options.length - 1

    while (start <= end) {
        const middle = Math.floor((start + end) / 2)
        const { value } = options[middle]

        if (isWithinDifferenceBounds(Number(value), key)) {
            return middle
        } else if (value < key) {
            start = middle + 1 // continue searching to the right
        } else {
            end = middle - 1 // continue searching to the left
        }
    }

    return -1
}

export function findClosestNumberSelectOption (
    options: NumberSelectOptions,
    value: number
): NumberSelectOption | null {
    const optionsBounds = 5
    const closestIndex = _findClosestIndex(options, value, 2)
    let result = null

    if (closestIndex) {
        const closestOptions = options.slice(closestIndex - optionsBounds, closestIndex + optionsBounds)

        result = closestOptions.reduce((a, b) => {
            return Math.abs(b.value - value) < Math.abs(a.value - value) ? b : a
        })
    }

    return result
}
