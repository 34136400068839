/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { PersonalData } from '@/store/user/personalData/types'

const STATE: PersonalData = {
    activity: ``,
    age: 0,
    anonymous: false,
    gender: ``,
    height: 0,
    weight: 0
}

export const useUserPersonalData = defineStore(`user/personalData`, {
    state: () => {
        return {
            ...cloneDeep(STATE)
        }
    }
})
