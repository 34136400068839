/* Logic */
import { defineComponent } from 'vue'

/* Components */
import Icon from '@/components/icon/icon.model.vue'

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        },
        iconClass: {
            type: String,
            required: false
        },
        dropShadow: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        alignLeft: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: `button`
        }
    },
    components: {
        Icon
    }
})
