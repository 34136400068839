import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-auto z-footer"
}
const _hoisted_2 = { class: "flex mr-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!
  const _component_NavigationContainer = _resolveComponent("NavigationContainer")!

  return (_ctx.visibility)
    ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createVNode(_component_NavigationContainer, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_IconButton, {
                icon: _ctx.ICONS.facebook,
                tag: "a",
                alignLeft: true,
                href: _ctx.CONTACT_INFORMATION.facebookPage,
                target: "_blank"
              }, null, 8, ["icon", "href"])
            ]),
            _createVNode(_component_DefaultButton, {
              class: "mr-24-16 z-0",
              tag: "router-link",
              to: _ctx.ROUTES.privacyPolicy.path,
              active: _ctx.isInvertedFooterLinks
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Privacy Policy ")
              ]),
              _: 1
            }, 8, ["to", "active"]),
            _createVNode(_component_DefaultButton, {
              class: "z-0",
              tag: "router-link",
              to: _ctx.ROUTES.termsOfService.path,
              alignRight: true,
              active: _ctx.isInvertedFooterLinks
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Terms of Service ")
              ]),
              _: 1
            }, 8, ["to", "active"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}