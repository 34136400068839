import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["font-medium h-39 pt-2 flex items-center", {
                    "text-action1": !_ctx.smallerText,
                    "text-action2": _ctx.smallerText,
                    "text-primary": !_ctx.active && !_ctx.inactive,
                    "text-inactive": _ctx.inactive,
                    "text-active": _ctx.active,
                    "px-action": !_ctx.dropShadow,
                    "ml-actionAlign": _ctx.alignLeft,
                    "mr-actionAlign": _ctx.alignRight,
                    "px-21 rounded-action bg-action shadow-actionDefault hover:shadow-actionHover": _ctx.dropShadow,
                    "justify-center": !_ctx.alignTextLeft,
                    "justify-start": _ctx.alignTextLeft,
                    "capitalize": _ctx.capitalize,
                    "pointer-events-none opacity-40": _ctx.disabled,
                    "cursor-default": !_ctx.enableHoverColor,
                    "md:hover:text-hover transition-all duration-action": _ctx.enableHoverColor
                }]),
    type: _ctx.tag === `button` ? `button` : false,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "type", "disabled"]))
}