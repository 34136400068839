export const CONVERSION_RATES: {
    [index: string]: number
} = {
    l: 1000000000, /* mcg */
    ml: 1000000, /* mcg */
    g: 1000000, /* mcg */
    mg: 1000, /* mcg */
    mcg: 1,
    kcal: 1,
    lb: 0.453592, /* kg */
    kg: 1, /* kg */
    cm: 1 /* cm */
}
