/* Logic */
import {
    defineComponent,
    ref
} from 'vue'
import {
    ref as storageRef,
    getDownloadURL
} from 'firebase/storage'
import { FirebaseStorage } from '@/database'
import { ImageIO } from '@/library/scripts/plugins/intersectionObserver'
import { handleDatabaseErrors } from '@/library/scripts/utils/database'

/* Config */
import {
    CONFIG,
    IMAGE_PROPS
} from '@/components/image/image.config'
import { ICONS } from '@/components/icon/icon.config'

/* Components */
import Icon from '@/components/icon/icon.model.vue'

export default defineComponent({
    props: IMAGE_PROPS,
    components: {
        Icon
    },
    setup () {
        const imageᴱ = ref<HTMLImageElement | null>(null)

        return {
            imageᴱ,
            ICONS
        }
    },
    data () {
        return {
            src: ``,
            imageLoaded: false,
            failedLoad: false
        }
    },
    computed: {
        showPlaceholder (): boolean {
            return this.fireStorage && !this.imageLoaded && !this.showError
        },
        imagePlaceholder (): string {
            let text = ``

            if (this.showPlaceholder) {
                const { placeholder } = this

                if (placeholder && placeholder.length > 0) {
                    const cleanPlaceholder = placeholder
                        .replace(/[^a-zA-Z ]/g, ``)
                        .replace(/ {2,}/g, ` `)

                    if (cleanPlaceholder.indexOf(` `) > 0) {
                        const nameWords = cleanPlaceholder.split(` `)

                        if (nameWords.length >= 2) {
                            text = `${nameWords[0].slice(0, 1)}${nameWords[1].slice(0, 1)}`
                        }
                    }

                    if (text.length === 0) {
                        text = cleanPlaceholder.slice(0, 2)
                    }
                }
            }

            return text
        },
        shadowSrc (): string {
            return require(`@/library/assets/${CONFIG.assetsFolders.images}/background/shadow.png`)
        }
    },
    methods: {
        addLoaderListener (): void {
            const imageᴱ = this.$refs.imageᴱ as HTMLImageElement

            this.$nextTick(() => {
                if (imageᴱ) {
                    imageᴱ.onload = () => {
                        this.imageLoaded = true
                    }
                }
            })
        },
        loadFireStorageImage (): void {
            const imageRef = storageRef(FirebaseStorage, this.imagePath)

            getDownloadURL(imageRef)
                .then((url) => {
                    this.src = url

                    this.addLoaderListener()
                })
                .catch((error) => {
                    handleDatabaseErrors(error)
                })
        },
        loadImage (): void {

            if (this.fireStorage && this.imagePath) {
                this.loadFireStorageImage()
            } else {
                try {
                    this.src = require(`@/library/assets/${CONFIG.assetsFolders.images}/${this.imagePath}`)

                    this.addLoaderListener()
                } catch (e) {
                    this.failedLoad = true
                }
            }
        },
        listenScrollIn (): void {
            ImageIO.observe(
                this.$el,
                this.loadImage
            )
        },
        addListeners (): void {
            this.listenScrollIn()
        }
    },
    watch: {
        imagePath () {
            this.loadImage()
        }
    },
    mounted (): void {
        this.addListeners()
    }
})
