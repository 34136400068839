/* Logic */
import { createValuesSameAsKeys } from '@/library/scripts/utils/object'

export const ICONS = createValuesSameAsKeys({
    arrow: ``,
    calendar: ``,
    cross: ``,
    crossSmall: ``,
    draggable: ``,
    edit: ``,
    email: ``,
    facebook: ``,
    google: ``,
    instagram: ``,
    leaf: ``,
    logo: ``,
    plus: ``,
    plusSmall: ``,
    settings: ``,
    user: ``,
    exclamationMark: ``
})

export const CONFIG = {
    svgMap: `svgMap.svg`,
    classPrefix: `icon--`
}
