import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "z-confirmationOverlay flex w-full h-full absolute" }
const _hoisted_2 = { class: "mt-28-5 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading2 = _resolveComponent("Heading2")!
  const _component_Text1 = _resolveComponent("Text1")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!
  const _component_WidgetContainer = _resolveComponent("WidgetContainer")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "fixed h-full w-full inset-0 items-center flex-col justify-center bg-overlay",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDecline && _ctx.onDecline(...args)))
    }),
    _createVNode(_component_WidgetContainer, {
      class: "shadow-widgetAngleDown flex flex-col z-0 m-auto max-w-487 min-w-322 z-0 pt-39-9 pb-39-13",
      isSwipeable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Heading2, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.options.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Text1, { class: "mt-34-14" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.displayedText), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DefaultButton, {
            class: "mr-24-16",
            alignRight: true,
            onClick: _ctx.onConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Yes ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_DefaultButton, {
            onClick: _ctx.onDecline,
            inactive: true
          }, {
            default: _withCtx(() => [
              _createTextVNode(" No ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 512)), [
    [_vShow, _ctx.visibility]
  ])
}