/* Logic */
import {
    ref,
    defineComponent
} from 'vue'
import { NotificationMessage } from './notifications.types'

/* Config */
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { CONFIG } from './notifications.config'

/* Components */
import DefaultContainer from '@/components/containers/defaultContainer.model.vue'

/* Types */
import { nanoid } from 'nanoid'

export default defineComponent({
    components: {
        DefaultContainer
    },
    setup () {
        const messages = ref<NotificationMessage[]>([])

        return {
            messages
        }
    },
    methods: {
        showNotificationMessage (message: string): void {
            const id = nanoid()

            this.messages.push({
                id,
                text: message
            })

            setTimeout(() => {
                const messageIndex = this.messages.findIndex((message) => {
                    return message.id === id
                })

                // Message could be previously deleted by hideNotificationMessage on click
                if (messageIndex >= 0) {
                    this.hideNotificationMessage(id)
                }
            }, CONFIG.notificationDuration)
        },
        hideNotificationMessage (messageId: string): void {
            const messageIndex = this.messages.findIndex((message) => {
                return message.id === messageId
            })
            const messageᴱ = document.getElementById(messageId)

            if (messageᴱ) {
                // Fixed width is added in order to prevent element loosing original width due to
                // "position: absolute" being added to it in order to enable proper sibling elements repositioning
                // Note: DOM Element.clientWidth value is rounded to lower for this reason 1px
                // should be added to neglect this inconsistency.
                messageᴱ.style.minWidth = `${messageᴱ.clientWidth + CONFIG.domWidthInconsistencyMargin}px`
            }

            this.messages.splice(messageIndex, 1)
        },
        addListeners (): void {
            this.$events.on(GLOBAL_EVENTS.notificationsShowMessage, this.showNotificationMessage)
        }
    },
    mounted (): void {
        this.addListeners()
    }
})
