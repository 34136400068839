/* Logic */
import {
    createRouter,
    createWebHistory,
    createMemoryHistory
} from 'vue-router'
import { beforeEachGuard } from '@/router/router.utils'
import { isPWA } from '@/library/scripts/utils/pwa'

/* Config */
import routes from './routes/index'

/*
In Safari touch/swipe from left to right conflicts with the native browsers behaviour
to navigate back in the browser history if there is more then 1 route in the browser history.
This approach replaces the previous route every time navigation occurs and ensures that there is only
one route in the browser history at all times if user uses PWA */
const Router = createRouter({
    history: isPWA() ? createMemoryHistory() : createWebHistory(),
    routes
})

Router.beforeEach(beforeEachGuard)

export default Router
