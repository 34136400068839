/* Logic */
import {
    defineComponent,
    ref
} from 'vue'

/* Config */
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'
import { ROUTES } from '@/router/router.config'
import { ICONS } from '@/components/icon/icon.config'

/* Components */
import DefaultNavigation from '@/components/base/components/navigation/components/defaultNavigation/defaultNavigation.model.vue'
import MealsPlanShortcut from './components/mealsPlanShortcut/mealsPlanShortcut.model.vue'
import DefaultContainer from '@/components/containers/defaultContainer.model.vue'
import IconButton from '@/components/buttons/iconButton/iconButton.model.vue'

export default defineComponent({
    components: {
        MealsPlanShortcut,
        DefaultNavigation,
        DefaultContainer,
        IconButton
    },
    setup () {
        const visibilityChangedFromMealsPlanToggle = ref(false)
        const elementsVisibility = ref({
            mealsPlanShortcut: false,
            navigationBar: true
        })

        return {
            visibilityChangedFromMealsPlanToggle,
            elementsVisibility,
            ICONS,
            ROUTES
        }
    },
    computed: {
        currentRoute (): string {
            return this.$route.path
        },
        iconClass (): string {
            let iconClass = `fill-active`

            if (this.elementsVisibility.mealsPlanShortcut) {
                iconClass = `fill-inactive rotate-45`
            }

            return iconClass
        }
    },
    methods: {
        toggleMealsPlanShortcut () {
            const elementsVisibility = this.elementsVisibility

            elementsVisibility.mealsPlanShortcut = !elementsVisibility.mealsPlanShortcut

            switch (true) {
                case elementsVisibility.mealsPlanShortcut && !elementsVisibility.navigationBar:
                    elementsVisibility.navigationBar = true
                    this.visibilityChangedFromMealsPlanToggle = true
                    break
                case this.visibilityChangedFromMealsPlanToggle:
                    elementsVisibility.navigationBar = false
                    this.visibilityChangedFromMealsPlanToggle = false
            }
        },
        // navigateToRoute (routeName: string): void {
        //     if (this.elementsVisibility.mealsPlanShortcut) {
        //         this.toggleMealsPlanShortcut()
        //     }
        //
        //     navigateTo(ROUTES[routeName])
        // },
        addListeners (): void {
            this.$events.on(GLOBAL_EVENTS.navigationHideMealsPlanShortcut, this.toggleMealsPlanShortcut)
        }
    },
    mounted (): void {
        this.addListeners()
    }
})
