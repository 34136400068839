import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_NavigationContainer = _resolveComponent("NavigationContainer")!

  return (_openBlock(), _createBlock(_component_NavigationContainer, {
    class: "backButton",
    onClick: _ctx.scrollToTheTop
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex fixed items-center bg-page w-full h-navigationHeight z-backButton transition-transform duration-38", _ctx.slideDownBackButton ? `-translate-y-0` : `-translate-y-full`])
      }, [
        _createVNode(_component_BackButton, { onClick: _ctx.navigateBack }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.backButtonText), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["onClick"]))
}