/* Logic */
import { defineStore } from 'pinia'

export const useRecipeEditorInterface = defineStore(`interface/recipeEditor`, {
    state: () => ({
        components: {
            foodList: {
                visibility: false
            },
            product: {
                visibility: false,
                id: ``
            },
            selectedGroup: {
                id: ``
            },
            recipe: {
                visibility: false,
                id: ``,
                components: {
                    information: false,
                    ingredients: true,
                    method: false
                }
            },
            editor: {
                visibility: true,
                components: {
                    information: false,
                    ingredients: true,
                    method: false
                }
            }
        }
    }),
    actions: {
        showFoodList (id: string): void {
            this.$patch({
                components: {
                    foodList: {
                        visibility: true
                    },
                    selectedGroup: {
                        id
                    }
                }
            })
        },
        showRecipe (id: string): void {
            this.$patch({
                components: {
                    recipe: {
                        visibility: true,
                        id
                    }
                }
            })
            this.hideProduct()
        },
        hideRecipe (): void {
            this.$patch({
                components: {
                    recipe: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        showProduct (id: string): void {
            this.$patch({
                components: {
                    product: {
                        visibility: true,
                        id
                    }
                }
            })
            this.hideRecipe()
        },
        hideProduct (): void {
            this.$patch({
                components: {
                    product: {
                        visibility: false,
                        id: ``
                    }
                }
            })
        },
        hideFoodList (): void {
            this.$patch({
                components: {
                    foodList: {
                        visibility: false
                    }
                }
            })
        },
        hideOptioalComponents (): void {
            this.hideFoodList()
            this.hideProduct()
            this.hideRecipe()
        }
    }
})
