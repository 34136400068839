export function isObject (value: any): value is Object {
    return typeof value === `object`
}

export function isBoolean (value: any): value is Boolean {
    return typeof value === `boolean`
}

export function isString (value: any): value is String {
    return typeof value === `string`
}

export function isNumber (value: any): value is Number {
    return typeof value === `number`
}

export function isUndefined (value: any): value is undefined {
    return typeof value === `undefined`
}
