/* Logic */
import { defineStore } from 'pinia'

export const useLoadingScreenInterface = defineStore(`interface/loadingScreen`, {
    state: () => ({
        visibility: true
    }),
    actions: {
        show (): void {
            this.visibility = true
        },
        hide (): void {
            // Allow for a small delay to allow for the loading screen to be visible so that the route changes are not visible
            setTimeout(() => {
                this.visibility = false
            }, 200)
        }
    }
})
