/* Config */
import * as NOTIFICATION_CONFIG from '@/components/base/components/notifications/notifications.config'

export const DEFAULT_LOADING_TEXT = `Loading` /* Same text should be used in public/index.html */
export const LOADING_SCREEN_TYPES = {
    initialisation: `initialisation`,
    authentication: `authentication`,
    personalisation: `personalisation`,
    default: `default`
}

const LOADING_TEXTS = {
    [LOADING_SCREEN_TYPES.default]: [
        `Starting up the engines`,
        `Loading resources`,
        `Authorising access`,
        `Checking configuration`
    ],
    [LOADING_SCREEN_TYPES.initialisation]: [
        `Starting up the engines`,
        `Loading resources`,
        `Checking configuration`,
        `Creating data models`
    ],
    [LOADING_SCREEN_TYPES.authentication]: [
        `Authenticating`,
        `Verifying user data`,
        `Authorising access`
    ],
    [LOADING_SCREEN_TYPES.personalisation]: [
        `Personalising`,
        `Checking configuration`,
        `Creating data models`
    ]
}

export const CONFIG = {
    hideDelay: 100, // small delay to allow route to finish rendering
    notifyErrorDelay: 20000,
    reloadDelay: NOTIFICATION_CONFIG.CONFIG.notificationDuration,
    DEFAULT_LOADING_TEXT,
    LOADING_SCREEN_TYPES,
    LOADING_TEXTS
}
