/* Logic */
import {
    createNumberSelectOptionsWithStep,
    createTextSelectOptions
} from '@/library/scripts/utils/select'
import { metricHeightToFeetInches } from '@/library/scripts/utils/conversion'

/* Config */
import { UNIT_SYSTEMS } from '@/library/scripts/values/units'
import { CONVERSION_RATES } from '@/library/scripts/values/conversion'

const WEIGHT_OPTIONS = {
    [UNIT_SYSTEMS.metric]: {
        min: 40,
        max: 200,
        step: CONVERSION_RATES.kg
    },
    [UNIT_SYSTEMS.imperial]: {
        min: 88,
        max: 440,
        step: CONVERSION_RATES.lb
    }
}

const HEIGHT_OPTIONS = {
    [UNIT_SYSTEMS.metric]: {
        min: 54,
        max: 272,
        step: CONVERSION_RATES.cm
    },
    [UNIT_SYSTEMS.imperial]: {
        min: 54,
        max: 272,
        step: CONVERSION_RATES.cm
    }
}

export const ACTIVITY_OPTIONS = {
    sedentary: `sedentary`,
    lowActive: `lowActive`,
    active: `active`,
    veryActive: `veryActive`
}

export const ACTIVITY_SELECT_OPTIONS = {
    [ACTIVITY_OPTIONS.sedentary]: `simple housework or gardening`,
    [ACTIVITY_OPTIONS.lowActive]: `30 min of moderate activity (e.g: walking/jogging)`,
    [ACTIVITY_OPTIONS.active]: `1 hour of moderate activity (e.g: walking/jogging)`,
    [ACTIVITY_OPTIONS.veryActive]: `45-60 min of vigorous activity (e.g: running/swimming)`
}

export const GENDER_OPTIONS = {
    male: `male`,
    female: `female`
}

export const PERSONAL_DATA_OPTIONS = {
    activity: createTextSelectOptions(ACTIVITY_SELECT_OPTIONS),
    age: {
        min: 18, /* Models for 18- aren't available yet */
        max: 120,
        step: 1
    },
    gender: createTextSelectOptions(GENDER_OPTIONS),
    displayName: {
        min: 4,
        max: 40
    },
    image: ``,
    height: {
        [UNIT_SYSTEMS.metric]: createNumberSelectOptionsWithStep(HEIGHT_OPTIONS.metric),
        [UNIT_SYSTEMS.imperial]: createNumberSelectOptionsWithStep(HEIGHT_OPTIONS.metric, metricHeightToFeetInches)
    },
    weight: {
        [UNIT_SYSTEMS.metric]: createNumberSelectOptionsWithStep(WEIGHT_OPTIONS.metric),
        [UNIT_SYSTEMS.imperial]: createNumberSelectOptionsWithStep(WEIGHT_OPTIONS.imperial, false, true)
    }
}
/* Average data found in google */
export const AVERAGE_PERSONAL_DATA = {
    age: 25,
    weightMale: 62,
    weightFemale: 50,
    heightMale: 171,
    heightFemale: 160,
    activity: ACTIVITY_OPTIONS.active
}
