<template>
    <p class='text-text1'
       :class='{
            "italic": italic,
            "text-inactive": inactive,
            "text-primary": !inactive,
            "font-medium": medium
        }'>
        <slot />
    </p>
</template>

<script>
import Text from './text.module.ts'

export default {
    extends: Text
}
</script>
