/* Logic */
import { defineComponent } from 'vue'

/* Config */
import { ICONS } from '@/components/icon/icon.config'

/* Components */
import Icon from '@/components/icon/icon.model.vue'

export default defineComponent({
    components: {
        Icon
    },
    data () {
        return {
            ICONS
        }
    }
})
