/* Logic */
import { useProductsStore } from '@/store/products'
import {
    findProductMeasure,
    findRecipeMeasure
} from '@/library/scripts/utils/measures'
import { detailedDiff } from 'deep-object-diff'
import { isEmptyObject } from '@/library/scripts/utils/object'
import { useRecipesStore } from '@/store/recipes'

/* Types */
import {
    AddedDifferences,
    BalanceRecordMealsPlanDifferences,
    DeletedDifferenceObject,
    DeletedDifferences,
    MealsPlanFoundDifferences,
    ProductUpdatedDifference,
    RecipeUpdatedDifference,
    UpdatedDifferences
} from '@/library/scripts/plugins/userDataManager/userDataManager.types'
import {
    BalanceRecordMealsPlan,
    BalanceRecordMealPlan
} from '@/library/scripts/types/balanceRecord'

function _getAddedDifferences (
    addedDifferences: AddedDifferences,
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealsPlan: BalanceRecordMealsPlan
) {
    const { getProduct } = useProductsStore()
    const { getRecipe } = useRecipesStore()

    Object.keys(addedDifferences).forEach((mealPlanName) => {
        const { products, recipes } = addedDifferences[mealPlanName]

        if (products) {
            for (const index in products) {
                const product = products[index]
                const productData = getProduct(product.id)

                if (productData) {
                    const { measures } = productData
                    const measure = findProductMeasure(measures, product.measureId)

                    if (measure) {
                        differences.products.push({
                            id: product.id,
                            mealPlanName,
                            quantityDifference: product.quantity * measure.weight,
                            mealPlan: modifiedMealsPlan[mealPlanName]
                        })
                    }
                }
            }
        }

        if (recipes) {
            for (const index in recipes) {
                const recipe = recipes[index]
                const recipeData = getRecipe(recipe.id)

                if (recipeData) {
                    const { measures } = recipeData
                    const measure = findRecipeMeasure(measures, recipe.measureId)
                    if (measure) {
                        differences.recipes.push({
                            id: recipe.id,
                            measureId: recipe.measureId,
                            mealPlanName,
                            quantityDifference: recipe.quantity * measure.weight,
                            mealPlan: modifiedMealsPlan[mealPlanName]
                        })
                    }
                }
            }
        }
    })

    return differences
}

function _getProductsUpdatedDifferences (
    products: ProductUpdatedDifference[],
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealPlan: BalanceRecordMealPlan,
    currentMealPlan: BalanceRecordMealPlan,
    mealPlanName: string
) {
    const { getProduct } = useProductsStore()

    for (const index in products) {
        const numberIndex = Number(index)
        const currentProduct = currentMealPlan.products[numberIndex]
        const product = products[numberIndex]
        const productData = getProduct(currentProduct.id)

        if (productData) {
            const { measures } = productData
            const currentMeasure = findProductMeasure(measures, currentProduct.measureId)
            const quantity = product.quantity ? product.quantity : currentProduct.quantity
            let currentQuantity = 0
            let modifiedQuantity = 0

            if (currentMeasure) {
                currentQuantity = currentProduct.quantity * currentMeasure.weight
            }

            if (product.measureId) {
                const measure = findProductMeasure(measures, product.measureId)

                if (measure) {
                    modifiedQuantity = quantity * measure.weight
                }
            } else if (currentMeasure) {
                modifiedQuantity = quantity * currentMeasure.weight
            }

            differences.products.push({
                id: currentProduct.id,
                mealPlanName,
                quantityDifference: modifiedQuantity - currentQuantity,
                mealPlan: modifiedMealPlan
            })
        }
    }
}

function _getRecipesUpdatedDifferences (
    recipes: RecipeUpdatedDifference[],
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealPlan: BalanceRecordMealPlan,
    currentMealPlan: BalanceRecordMealPlan,
    mealPlanName: string
) {
    const { getRecipe } = useRecipesStore()

    for (const index in recipes) {
        const numberIndex = Number(index)
        const currentRecipe = currentMealPlan.recipes[numberIndex]
        const recipe = recipes[numberIndex]
        const recipeData = getRecipe(currentRecipe.id)

        if (recipeData) {
            const { measures } = recipeData
            const currentMeasure = findRecipeMeasure(measures, currentRecipe.measureId)
            let currentQuantity = 0
            let modifiedQuantity = 0

            if (currentMeasure) {
                currentQuantity = currentRecipe.quantity * currentMeasure.weight
            }

            if (recipe.measureId) {
                const measure = findRecipeMeasure(measures, recipe.measureId)

                if (measure) {
                    modifiedQuantity = recipe.quantity * measure.weight
                }
            } else if (currentMeasure) {
                modifiedQuantity = recipe.quantity * currentMeasure.weight
            }

            differences.recipes.push({
                id: currentRecipe.id,
                measureId: currentRecipe.measureId,
                mealPlan: modifiedMealPlan,
                mealPlanName,
                quantityDifference: modifiedQuantity - currentQuantity
            })
        }
    }
}

function _getUpdatedDifferences (
    updatedDifferences: UpdatedDifferences,
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealsPlan: BalanceRecordMealsPlan,
    currentMealsPlan: BalanceRecordMealsPlan
) {
    Object.keys(updatedDifferences).forEach((mealPlanName) => {
        const { products, recipes } = updatedDifferences[mealPlanName]

        if (products) {
            _getProductsUpdatedDifferences(
                products,
                differences,
                modifiedMealsPlan[mealPlanName],
                currentMealsPlan[mealPlanName],
                mealPlanName
            )
        }

        if (recipes) {
            _getRecipesUpdatedDifferences(
                recipes,
                differences,
                modifiedMealsPlan[mealPlanName],
                currentMealsPlan[mealPlanName],
                mealPlanName
            )
        }
    })

    return differences
}

function _getProductDeletedDifference (
    products: DeletedDifferenceObject,
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealPlan: BalanceRecordMealPlan,
    currentMealPlan: BalanceRecordMealPlan,
    mealPlanName: string
) {
    const { getProduct } = useProductsStore()

    for (const index in products) {
        const numberIndex = Number(index)
        const currentProduct = currentMealPlan.products[numberIndex]
        const product = getProduct(currentProduct.id)
        let currentQuantity = 0

        if (product) {
            const { measures } = product
            const currentMeasure = findProductMeasure(measures, currentProduct.measureId)

            if (currentMeasure) {
                currentQuantity = currentProduct.quantity * currentMeasure.weight
            }
        }


        differences.products.push({
            id: currentProduct.id,
            mealPlan: modifiedMealPlan,
            mealPlanName,
            quantityDifference: -currentQuantity
        })
    }
}

function _getRecipeDeletedDifference (
    recipes: DeletedDifferenceObject,
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealPlan: BalanceRecordMealPlan,
    currentMealPlan: BalanceRecordMealPlan,
    mealPlanName: string
) {
    const { getRecipe } = useRecipesStore()

    for (const index in recipes) {
        const numberIndex = Number(index)
        const currentRecipe = currentMealPlan.recipes[numberIndex]
        const recipeData = getRecipe(currentRecipe.id)

        if (recipeData) {
            const { measures } = recipeData
            const measure = findRecipeMeasure(measures, currentRecipe.measureId)

            if (measure) {
                differences.recipes.push({
                    id: currentRecipe.id,
                    measureId: currentRecipe.measureId,
                    mealPlan: modifiedMealPlan,
                    mealPlanName,
                    quantityDifference: -currentRecipe.quantity * measure.weight
                })
            }
        }
    }
}

function _getDeletedDifferences (
    deletedDifferences: DeletedDifferences,
    differences: BalanceRecordMealsPlanDifferences,
    modifiedMealsPlan: BalanceRecordMealsPlan,
    currentMealsPlan: BalanceRecordMealsPlan
) {
    Object.keys(deletedDifferences).forEach((mealPlanName) => {
        const { products, recipes } = deletedDifferences[mealPlanName]

        if (products) {
            _getProductDeletedDifference(
                products,
                differences,
                modifiedMealsPlan[mealPlanName],
                currentMealsPlan[mealPlanName],
                mealPlanName
            )
        }

        if (recipes) {
            _getRecipeDeletedDifference(
                recipes,
                differences,
                modifiedMealsPlan[mealPlanName],
                currentMealsPlan[mealPlanName],
                mealPlanName
            )
        }
    })
}

export function getBalanceRecordMealsPlanDifferences (
    currentMealsPlan: BalanceRecordMealsPlan,
    modifiedMealsPlan: BalanceRecordMealsPlan
): BalanceRecordMealsPlanDifferences {
    const foundDifferences = detailedDiff(currentMealsPlan, modifiedMealsPlan) as MealsPlanFoundDifferences
    const differences: BalanceRecordMealsPlanDifferences = {
        products: [],
        recipes: []
    }

    if (!isEmptyObject(foundDifferences.added)) {
        _getAddedDifferences(foundDifferences.added, differences, modifiedMealsPlan)
    }

    if (!isEmptyObject(foundDifferences.updated)) {
        _getUpdatedDifferences(foundDifferences.updated, differences, modifiedMealsPlan, currentMealsPlan)
    }

    if (!isEmptyObject(foundDifferences.deleted)) {
        _getDeletedDifferences(foundDifferences.deleted, differences, modifiedMealsPlan, currentMealsPlan)
    }

    return differences
}
