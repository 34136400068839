/* Logic */
import { defineStore } from 'pinia'
import { isPWA } from '@/library/scripts/utils/pwa'

/* Config */
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'

/* Types */
import { InterfaceRouterHistoryRoute } from '@/store/interface/types'

export const useInterface = defineStore(`interface`, {
    state: () => ({
        breakPoint: BREAKPOINTS.xsm,
        isPWA: isPWA(),
        routerHistory: [] as InterfaceRouterHistoryRoute[]
    })
})

export {
    useBalanceInterface,
    getInitialInterfaceBalance
} from './balance'
export { useLoadingScreenInterface } from './loadingScreen'
export { useProfileInterface } from './profile'
export { useRecipeEditorInterface } from './recipeEditor'
export { useRecipesInterface } from './recipes'
