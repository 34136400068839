<template>
    <Component class='text-heading4'
               :class='classes'
               :is='tag'>
        <slot />
    </Component>
</template>

<script>
import Heading from './heading.module.ts'

export default {
    extends: Heading,
    props: {
        tag: {
            type: String,
            default: `h4`
        }
    }
}
</script>
