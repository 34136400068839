/* Logic */
import { createNutrientsModelMap } from '@/library/scripts/utils/nutrients'
import { createValuesSameAsKeys } from '@/library/scripts/utils/object'

/* Types */
import {
    NutrientsModelMap,
    NutrientsListByContainerCategory
} from '@/library/scripts/types/nutrients'

export const STORED_NUTRIENTS_DIVIDER = 100

/* Other lipids' nutrients are not recommended for consumption and for this reason
should not be used for calculation of macroNutrients completeness */
export const LIPIDS_PROGRESS_NUTRIENTS = [
    `polyunsaturated`,
    `monounsaturated`
]

/*
Also represents rendering order */
export const CONTAINER_CATEGORIES = createValuesSameAsKeys({
    carbohydrates: ``,
    energy: ``,
    lipids: ``,
    minerals: ``,
    proteins: ``,
    vitamins: ``,
    water: ``
})

/*
Also represents rendering order */
export const COMPLETENESS_CATEGORIES = createValuesSameAsKeys({
    nutrients: ``,
    [CONTAINER_CATEGORIES.water]: ``,
    [CONTAINER_CATEGORIES.energy]: ``
})

/*
The order of the values is important since it determines the
nutrientSections order during the components rendering */
export const NUTRIENTS_LIST_BY_CONTAINER_CATEGORY: NutrientsListByContainerCategory = {
    [CONTAINER_CATEGORIES.energy]: [],
    [CONTAINER_CATEGORIES.lipids]: [
        `monounsaturated`,
        `polyunsaturated`,
        `omega3`
        // `saturated`
        // `cholesterol`,
        // `trans`
    ],
    [CONTAINER_CATEGORIES.water]: [],
    [CONTAINER_CATEGORIES.carbohydrates]: [
        `dietaryFiber`,
        `sugars`
    ],
    [CONTAINER_CATEGORIES.proteins]: [
        `histidine`,
        `isoleucine`,
        `leucine`,
        `lysine`,
        `methionine`,
        `phenylalanine`,
        `threonine`,
        `tryptophan`,
        `valine`
    ],
    [CONTAINER_CATEGORIES.vitamins]: [
        `vitaminA`,
        `vitaminD`,
        `vitaminE`,
        `vitaminK`,
        `vitaminB1`,
        `vitaminB2`,
        `vitaminB3`,
        `vitaminB5`,
        `vitaminB6`,
        `vitaminB7`,
        `vitaminB9`,
        `vitaminB12`,
        `vitaminC`,
        `choline`
    ],
    [CONTAINER_CATEGORIES.minerals]: [
        `calcium`,
        `chloride`,
        `chromium`,
        `copper`,
        `fluoride`,
        `iodine`,
        `iron`,
        `magnesium`,
        `manganese`,
        `molybdenum`,
        `phosphorus`,
        `potassium`,
        `selenium`,
        `sodium`,
        `zinc`
    ]
}

export const NUTRIENTS_MODEL_MAP: NutrientsModelMap = createNutrientsModelMap(NUTRIENTS_LIST_BY_CONTAINER_CATEGORY)
