/* Config */
import { CONVERSION_RATES } from '@/library/scripts/values/conversion'
import { FOOD_MEASURE_UNITS } from '@/library/scripts/values/units'
import {
    CONTAINER_CATEGORIES,
    STORED_NUTRIENTS_DIVIDER
} from '@/library/scripts/values/nutrients'

export function convertUnits (
    value: number,
    currentUnit: number,
    nextUnit: number,
    round?: boolean
): number {
    if (round) {
        return Math.round(currentUnit / nextUnit * value)
    } else {
        return currentUnit / nextUnit * value
    }
}

export function getFormattedQuantityAndUnit (
    quantity: number,
    sectionName: string
) {
    let convertedQuantity
    let convertedUnit

    switch (true) {
        case sectionName === CONTAINER_CATEGORIES.water && quantity >= CONVERSION_RATES.l / 10:
            convertedQuantity = (quantity / CONVERSION_RATES.l).toFixed(2)
            convertedUnit = FOOD_MEASURE_UNITS.l
            break
        case sectionName === CONTAINER_CATEGORIES.water && quantity >= 0:
            convertedQuantity = (quantity / CONVERSION_RATES.ml).toFixed(2)
            convertedUnit = FOOD_MEASURE_UNITS.ml
            break
        case sectionName === CONTAINER_CATEGORIES.energy && quantity >= 0:
            convertedQuantity = quantity.toFixed(0)
            convertedUnit = FOOD_MEASURE_UNITS.kcal
            break
        case quantity >= CONVERSION_RATES.g / 10:
            convertedQuantity = (quantity / CONVERSION_RATES.g).toFixed(1)
            convertedUnit = FOOD_MEASURE_UNITS.g
            break
        case quantity >= CONVERSION_RATES.mg:
            convertedQuantity = (quantity / CONVERSION_RATES.mg).toFixed(2)
            convertedUnit = FOOD_MEASURE_UNITS.mg
            break
        case quantity > 0:
            convertedQuantity = quantity.toFixed(2)
            convertedUnit = FOOD_MEASURE_UNITS.mcg
            break
        default:
            convertedQuantity = quantity.toFixed(0)
            convertedUnit = FOOD_MEASURE_UNITS.mcg
    }

    return {
        quantity: parseFloat(convertedQuantity),
        unit: convertedUnit
    }
}

export function metricHeightToFeetInches (n: number) {
    const realFeet = ((n * 0.393700) / 12)
    const feet = Math.floor(realFeet)
    const inches = Math.round((realFeet - feet) * 12)

    return `${feet}' ${inches}"`
}

/**
 * Collected nutrients are stored per 1g of product in mcg
 */
export function getDividedQuantityForStorage (
    quantity: number,
    unit: string
) {
    return quantity * CONVERSION_RATES[unit] / STORED_NUTRIENTS_DIVIDER
}

/**
 * Collected nutrients are stored per 1g of product in mcg
 */
export function getUndoneDividedQuantityForStorage (
    quantity: number,
    unit: string
) {
    return quantity / CONVERSION_RATES[unit] * STORED_NUTRIENTS_DIVIDER
}
