/* Types */
import { ObjectWithStrings } from '@/library/scripts/types/types'

const metric = {
    cm: `cm`,
    kg: `kg`
}

const imperial = {
    lb: `lb`,
    feet: `feet`,
    inch: `inch`
}

export const UNIT_SYSTEMS = {
    metric: `metric`,
    imperial: `imperial`
}

export const UNITS = {
    metric,
    imperial
}

export const HEIGHT_UNITS = {
    [metric.cm]: metric.cm,
    [imperial.feet]: `${imperial.feet} & ${imperial.inch}es`
}

export const WEIGHT_UNITS = {
    [metric.kg]: metric.kg,
    [imperial.lb]: imperial.lb
}

export const HUMAN_MEASURE_UNITS = {
    height: {
        [UNIT_SYSTEMS.metric]: metric.cm,
        [UNIT_SYSTEMS.imperial]: imperial.feet
    },
    weight: {
        [UNIT_SYSTEMS.metric]: metric.kg,
        [UNIT_SYSTEMS.imperial]: imperial.lb
    }
}

export const FOOD_MEASURE_UNITS: ObjectWithStrings = {
    l: `l`,
    ml: `ml`,
    kg: `kg`,
    g: `g`,
    mg: `mg`,
    mcg: `mcg`,
    kcal: `kcal`
}

export const BALANCE_MODEL_UNITS: ObjectWithStrings = {
    ...FOOD_MEASURE_UNITS,
    percentage: `%`
}

export const NUTRIENT_SECTION_VALUES = {
    unit: `unit`,
    AMDR: `AMDR`
}
