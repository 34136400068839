<template>
    <DefaultContainer class='h-navigationHeight flex justify-between items-center'>
        <slot />
    </DefaultContainer>
</template>

<script>
import DefaultContainer from '@/components/containers/defaultContainer.model.vue'

export default {
    components: {
        DefaultContainer
    }
}
</script>
