import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationOverlay = _resolveComponent("ConfirmationOverlay")!
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_VersionUpdater = _resolveComponent("VersionUpdater")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationOverlay),
    _createVNode(_component_LoadingScreen),
    _createVNode(_component_Notifications),
    _createVNode(_component_VersionUpdater),
    _createVNode(_component_Navigation),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer)
  ], 64))
}