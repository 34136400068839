/* Config */
import { MEAL_PLAN_NAMES } from '@/library/scripts/values/mealPlans'
import { FOOD_MEASURE_UNITS } from '@/library/scripts/values/units'

/* Types */
import { EnergyRecommendations } from './mealPlan.types'
import { BalanceRecordDefaultCompletenessValues } from '@/library/scripts/types/balanceRecord'

export function createEnergyRecommendations (energyCompleteness: BalanceRecordDefaultCompletenessValues, mealPlanName: string): EnergyRecommendations {
    const { recommended } = energyCompleteness
    const energyRecommendations = {
        from: 0,
        to: 0,
        unit: FOOD_MEASURE_UNITS.kcal
    }

    switch (mealPlanName) {
        case MEAL_PLAN_NAMES.breakfast:
            energyRecommendations.from = Math.round(recommended * 0.2501078051)
            energyRecommendations.to = Math.round(recommended * 0.3501509271)
            break
        case MEAL_PLAN_NAMES.lunch:
            energyRecommendations.from = Math.round(recommended * 0.3001293661)
            energyRecommendations.to = Math.round(recommended * 0.3997412678)
            break
        case MEAL_PLAN_NAMES.dinner:
            energyRecommendations.from = Math.round(recommended * 0.3898231997)
            energyRecommendations.to = Math.round(recommended * 0.5101336783)
            break
        case MEAL_PLAN_NAMES.snacks:
            energyRecommendations.from = Math.round(recommended * 0.05993962915)
            break
    }

    return energyRecommendations
}
