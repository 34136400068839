/* Config */
import {
    DEFAULT_MEASURE_ID,
    PRODUCT_GRAM_MEASURE,
    PRODUCT_ML_MEASURE
} from '@/library/scripts/values/measures'
import { PRODUCTS_CATEGORIES } from '@/library/scripts/values/products'

/* Types */
import {
    ProductMeasure,
    ProductMeasures
} from '@/library/scripts/types/product'
import {
    RecipeMeasure,
    RecipeMeasures,
} from '@/library/scripts/types/recipe'
import { FOOD_MEASURE_UNITS } from '@/library/scripts/values/units'

export function getProductDefaultMeasure (
    productCategory?: string
): ProductMeasure {
    const mlCategories = [
        PRODUCTS_CATEGORIES.beverages,
        PRODUCTS_CATEGORIES.oils
    ]
    let measure

    if (productCategory && mlCategories.includes(productCategory)) {
        measure = PRODUCT_ML_MEASURE
    } else {
        measure = PRODUCT_GRAM_MEASURE
    }

    return measure
}

export function findProductMeasure (
    measures: ProductMeasures,
    measureId: string,
    productCategory?: string
): ProductMeasure | undefined {
    if (measureId === DEFAULT_MEASURE_ID) {
        return getProductDefaultMeasure(productCategory)
    } else {
        return measures.find((measure) => measure.id === measureId)
    }
}

export function findRecipeMeasure (
    measures: RecipeMeasures,
    measureId: string
): RecipeMeasure | undefined {
    return measures.find((measure) => measure.id === measureId)
}

export function findRecipePrimaryMeasure (
    measures: RecipeMeasures
): RecipeMeasure | undefined {
    const sortedMeasures = measures.sort((a, b) => a.rank - b.rank)
    const primaryMeasure = sortedMeasures.find((measure) => measure.recipeTotal > 0)

    return primaryMeasure || sortedMeasures[0]
}

export function sortMeasuresByRank (measures: ProductMeasures): ProductMeasures {
    return measures.sort((a, b) => a.rank - b.rank)
}

export function getMeasureDescription (
    quantity: number,
    description: string,
): string {
    const noSpaceUnits = [
        FOOD_MEASURE_UNITS.g,
        FOOD_MEASURE_UNITS.ml
    ]
    let text

    if (noSpaceUnits.includes(description)) {
        text = `${quantity}${description}`
    } else {
        text = `${quantity} ${description}`
    }

    return text
}
