/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Config */
import { UNIT_SYSTEMS } from '@/library/scripts/values/units'

/* Types */
import { Config } from '@/store/user/config/types'

const STATE: Config = {
    admin: false,
    suggestApp: false,
    displayWelcome: false,
    anonymous: false,
    unitSystem: UNIT_SYSTEMS.metric
}

export const useUserConfig = defineStore(`user/config`, {
    state: () => {
        return {
            ...STATE
        }
    }
})
