/* Logic */
import { $events } from '@/index'
import { isNumber } from '@/library/scripts/utils/types'
import { useInterface } from '@/store/interface'

/* Config */
import { BREAKPOINTS } from '@/library/scripts/values/breakpoints'
import { GLOBAL_EVENTS } from '@/library/scripts/values/events'

/**
 * Implementation uses MediaQueryList API, the documentation can be found:
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 *
 * @class MediaQueryListListenerClass
 */
class MediaQueryListListenerClass {
    private breakPoints = Object.values(BREAKPOINTS)
    private mediaQueryLists = [] as MediaQueryList[]
    private minWidthRegExp = /min-width:\s*(\d+)/

    private createMediaQueryList () {
        const { breakPoints } = this
        const sortedBreakPoints = breakPoints.sort((a, b) => {
            return a - b
        })

        sortedBreakPoints.forEach((breakpoint, index) => {
            const minBreakpoint = breakpoint
            const maxBreakpoint = sortedBreakPoints[index + 1]
            const mediaQueryString = maxBreakpoint
                ? `(min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint - 1}px)`
                : `(min-width: ${minBreakpoint}px)`
            const mediaQueryList = window.matchMedia(mediaQueryString)

            this.mediaQueryLists.push(mediaQueryList)
        })
    }

    private getInitialBreakPoint () {
        const viewPortWidth = window.innerWidth
        let initialBreakPoint = 0

        this.breakPoints.forEach((breakPoint) => {
            if (breakPoint <= viewPortWidth) {
                initialBreakPoint = breakPoint
            }
        })

        return initialBreakPoint
    }

    private addMediaQueryListListeners () {
        this.mediaQueryLists.forEach((mediaQueryList) => {
            mediaQueryList.addListener(this.checkBreakpointStatus.bind(this))
        })
    }

    /**
     * @param {string} mediaQuery
     */
    private getNewBreakPoint (mediaQuery: string) {
        const regExpMatch = this.minWidthRegExp.exec(mediaQuery)

        if (regExpMatch) {
            /*
            breakPoint value as String is stored at 1 index, e.g "640" */
            return parseInt(regExpMatch[1])
        }
    }

    /**
     * Store reference in the store, so that new loaded components
     * can have access to the current breakpoint
     *
     * @param {number} newBreakPoint
     * @param {boolean} notify
     */
    private notifyAndStoreBreakPointChange (newBreakPoint: number, notify: boolean = true) {
        const interfaceStore = useInterface()

        interfaceStore.breakPoint = newBreakPoint

        if (notify) {
            $events.emit(GLOBAL_EVENTS.baseBreakPointChange, newBreakPoint)
        }
    }

    /**
     * MediaQueryListEvent callbacks are triggered twice:
     * - first time for the breakpoint that has left viewport
     * - second time for the breakpoint that has entered viewport
     *
     * For this reason, we should check if the breakpoint has already previously changed,
     * in order to avoid double execution of callbacks
     */
    private checkBreakpointStatus ({ matches, media }: MediaQueryListEvent) {
        if (matches) {
            const newBreakPoint = this.getNewBreakPoint(media)

            /*
            Allow newBreakPoint === 0 to pass */
            if (isNumber(newBreakPoint)) {
                this.notifyAndStoreBreakPointChange(newBreakPoint)
            }
        }
    }

    public init () {
        const initialBreakPoint = this.getInitialBreakPoint()

        if (initialBreakPoint) {
            this.notifyAndStoreBreakPointChange(initialBreakPoint, false)
        }

        this.createMediaQueryList()
        this.addMediaQueryListListeners()
    }
}

export const MediaQueryListListener = new MediaQueryListListenerClass()
