/* Logic */
import {
    doc,
    writeBatch
} from 'firebase/firestore'
import { FirebaseFS } from '@/database'
import { createURLPath } from '@/library/scripts/utils/string'
import { useUserAuthentication } from '@/store/user'

/* Types */
import { UpdateUserDataOptions } from '@/library/scripts/plugins/userDataManager/userDataManager.types'

/* Config */
import { FIRESTORE_COLLECTIONS } from '@/library/scripts/plugins/userDataManager/userDataManager.config'

export function updateUserData (options: UpdateUserDataOptions): Promise<any> {
    const { id } = useUserAuthentication()
    const userDataBatch = writeBatch(FirebaseFS)
    const userDataCollectionPath = createURLPath(
        FIRESTORE_COLLECTIONS.users,
        id,
        FIRESTORE_COLLECTIONS.userData
    )

    options.forEach((option) => {
        const optionDocRef = doc(
            FirebaseFS,
            createURLPath(userDataCollectionPath, option.valueName)
        )

        userDataBatch.set(optionDocRef, option.value, { merge: true })
    })

    return userDataBatch.commit()
}
