/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { Recipes } from '@/library/scripts/types/recipe'

const recipes: Recipes = {}

export const useUserRecipes = defineStore(`user/recipes`, {
    state: () => ({
        recipes: cloneDeep(recipes)
    }),
    getters: {
        recipesQuantity (state) {
            return Object.keys(state.recipes).length
        }
    }
})
