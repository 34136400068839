export const CONFIG = {
    updateCheckInterval: 3600000, /* 1 hour */
    pathToServiceWorker: `serviceWorker.js`
}

export const SERVICE_WORKER_EVENTS = {
    updated: `serviceWorker:updated`
}

export const SERVICE_WORKER_MESSAGES = {
    skipWaiting: `skipWaiting`
}
