import { createValuesSameAsKeys } from '@/library/scripts/utils/object'

export const PRODUCTS_STATES = createValuesSameAsKeys({
    baked: ``,
    boiled: ``,
    canned: ``,
    cooked: ``,
    dried: ``,
    raw: ``,
    roasted: ``,
    sprouted: ``
})

export const NONE_PRODUCT_STATE_NAME = `none`
export const NONE_PRODUCT_STATE_ID = ``

export const NONE_PRODUCT_STATE = {
    [NONE_PRODUCT_STATE_ID]: NONE_PRODUCT_STATE_NAME,
}

export const PRODUCTS_CATEGORIES = createValuesSameAsKeys({
    // algae: ``,
    bakedProducts: `baked products`,
    beverages: ``,
    bulbs: ``,
    flours: ``,
    flowers: ``,
    fruits: ``,
    grains: ``,
    leaves: ``,
    legumes: ``,
    mushrooms: ``,
    nuts: ``,
    oils: ``,
    others: ``,
    proteins: ``,
    roots: ``,
    seeds: ``,
    spices: ``,
    stems: ``,
    sweets: ``,
    tubers: ``
})

export const USER_PRODUCTS_CATEGORIES = createValuesSameAsKeys({
    custom: ``,
    supplements: ``
})
