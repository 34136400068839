/* Logic */
import { diff } from 'deep-object-diff'

/* Types */
import { isObject } from '@/library/scripts/utils/types'
import { ValueOf } from '@/library/scripts/types/types'

/* Redeclared because webpack fails to resolve during compilation */
type ObjectWithStrings = {
    [index: string]: string
}

type PropMap<T> = {
    [K in keyof T]: K;
}

export function hasOwnProperty (
    object: Object,
    property: string
): boolean {
    return Object.prototype.hasOwnProperty.call(object, property)
}

export function cloneDeep<T> (object: T): T {
    return JSON.parse(JSON.stringify(object)) as T
}

export function createObjectKeys<T> (object: T): PropMap<T> {
    const keys: ObjectWithStrings = {}

    Object.keys(object).forEach((propertyName) => {
        keys[propertyName] = propertyName
    })

    return keys as PropMap<T>
}

export function isEmptyObject (object: any) {
    let isEmpty = true

    if (isObject(object)) {
        isEmpty = Object.keys(object).length === 0
    }

    return isEmpty
}

export function objectHasChanged (
    originalObject: Object,
    newObject: Object
): boolean {
    const changes = diff(originalObject, newObject)

    return !isEmptyObject(changes)
}

export function objectToArray<T> (
    object: T
): ValueOf<T>[] {
    return Object.values(object)
}

export function createValuesSameAsKeys (object: ObjectWithStrings): ObjectWithStrings {
    const result: ObjectWithStrings = {}

    return Object.keys(object)
        .reduce((result, key) => {
            if (object[key]) {
                result[key] = object[key]
            } else {
                result[key] = key
            }

            return result
        }, result)
}
