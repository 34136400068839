/* Config */
import { FOOD_MEASURE_UNITS } from '@/library/scripts/values/units'
import { STORED_NUTRIENTS_DIVIDER } from '@/library/scripts/values/nutrients'

/* Types */
import { ProductMeasure } from '@/library/scripts/types/product'
import { RecipeMeasure } from '@/library/scripts/types/recipe'

/* Fallback */
export const FALLBACK_MEASURE_ID = `fallback`
export const FALLBACK_MEASURE_RANK = -1

/* Default */
export const DEFAULT_MEASURE_ID = `default`
export const DEFAULT_MEASURE_RANK = 0
export const DEFAULT_MEASURE_SELECTED_QUANTITY = 100

/* Product */
export const PRODUCT_CUSTOM_MEASURE_SELECTED_QUANTITY = 1
export const PRODUCT_DEFAULT_MEASURE_WEIGHT = 1

/* User Product */
export const USER_PRODUCT_CUSTOM_DEFAULT_MEASURE_ID = `customDefault`
export const USER_PRODUCT_DEFAULT_MEASURE_WEIGHT = 1
export const USER_PRODUCT_DEFAULT_CUSTOM_MEASURE_WEIGHT = STORED_NUTRIENTS_DIVIDER
export const USER_PRODUCT_DEFAULT_MEASURE_SELECTED_QUANTITY = 100
export const USER_PRODUCT_DEFAULT_MEASURE_WEIGHT_DESCRIPTION = `${USER_PRODUCT_DEFAULT_MEASURE_SELECTED_QUANTITY}${FOOD_MEASURE_UNITS.g}`

/* Recipe */
export const RECIPE_DEFAULT_SELECTED_SERVING_QUANTITY = 1
export const RECIPE_SERVING_MEASURE_ID = `serving`
export const RECIPE_GRAM_MEASURE_ID = `g`
export const RECIPE_ML_MEASURE_ID = `ml`
export const RECIPE_SERVING_MEASURE_RANK = DEFAULT_MEASURE_RANK
export const RECIPE_GRAM_MEASURE_RANK = RECIPE_SERVING_MEASURE_RANK + 1
export const RECIPE_ML_MEASURE_RANK = RECIPE_GRAM_MEASURE_RANK + 1
export const RECIPE_SERVING_MEASURE_DESCRIPTION = `serving`
export const RECIPE_DEFAULT_MEASURE_WEIGHT = 1

/* Product */
export const PRODUCT_FALLBACK_MEASURE: ProductMeasure = {
    description: `no measure`,
    id: FALLBACK_MEASURE_ID,
    rank: FALLBACK_MEASURE_RANK,
    weight: 0,
}

export const PRODUCT_GRAM_MEASURE: ProductMeasure = {
    description: FOOD_MEASURE_UNITS.g,
    id: DEFAULT_MEASURE_ID,
    rank: DEFAULT_MEASURE_RANK,
    weight: PRODUCT_DEFAULT_MEASURE_WEIGHT,
}

export const PRODUCT_ML_MEASURE: ProductMeasure = {
    description: FOOD_MEASURE_UNITS.ml,
    id: DEFAULT_MEASURE_ID,
    rank: DEFAULT_MEASURE_RANK,
    weight: PRODUCT_DEFAULT_MEASURE_WEIGHT
}

/* User Product */
export const USER_PRODUCT_DEFAULT_MEASURE: ProductMeasure = {
    description: FOOD_MEASURE_UNITS.g,
    id: DEFAULT_MEASURE_ID,
    rank: DEFAULT_MEASURE_RANK,
    weight: USER_PRODUCT_DEFAULT_MEASURE_WEIGHT
}

export const USER_PRODUCT_CUSTOM_DEFAULT_MEASURE: ProductMeasure = {
    description: ``,
    id: USER_PRODUCT_CUSTOM_DEFAULT_MEASURE_ID,
    rank: DEFAULT_MEASURE_RANK,
    weight: USER_PRODUCT_DEFAULT_CUSTOM_MEASURE_WEIGHT
}

/* Recipe */
export const RECIPE_FALLBACK_MEAURE: RecipeMeasure = {
    description: `no measure`,
    id: FALLBACK_MEASURE_ID,
    rank: FALLBACK_MEASURE_RANK,
    recipeTotal: 0,
    weight: 0
}

export const RECIPE_SERVING_MEASURE: RecipeMeasure = {
    description: RECIPE_SERVING_MEASURE_DESCRIPTION,
    id: RECIPE_SERVING_MEASURE_ID,
    rank: RECIPE_SERVING_MEASURE_RANK,
    recipeTotal: 0,
    weight: RECIPE_DEFAULT_MEASURE_WEIGHT
}

export const RECIPE_GRAM_MEASURE: RecipeMeasure = {
    description: FOOD_MEASURE_UNITS.g,
    id: RECIPE_GRAM_MEASURE_ID,
    rank: RECIPE_GRAM_MEASURE_RANK,
    recipeTotal: 0,
    weight: RECIPE_DEFAULT_MEASURE_WEIGHT
}

export const RECIPE_ML_MEASURE: RecipeMeasure = {
    description: FOOD_MEASURE_UNITS.ml,
    id: RECIPE_ML_MEASURE_ID,
    rank: RECIPE_ML_MEASURE_RANK,
    recipeTotal: 0,
    weight: RECIPE_DEFAULT_MEASURE_WEIGHT
}
