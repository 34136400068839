/** Alternatives for body scroll lock:
 * https://www.npmjs.com/package/body-scroll-lock
 * https://www.npmjs.com/package/tua-body-scroll-lock
 * https://www.npmjs.com/package/scroll-lock
 */
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
// @ts-ignore
} from 'body-scroll-lock';

export function lockScroll (element: HTMLElement): void {
    disableBodyScroll(element)
}

export function unlockScroll (element: HTMLElement): void {
    enableBodyScroll(element)
}

export function clearAllScrollLocks (): void {
    clearAllBodyScrollLocks()
}
