/* Logic */
import { navigateTo } from '@/router/router.utils'
import {
    getBalanceModelPath,
    getRecipesDataSnapshotChanges,
    saveAuthors,
    saveRecipes
} from '@/library/scripts/plugins/userDataManager/userDataManager.utils'
import { getBalanceRecordMealsPlanDifferences } from '@/library/scripts/plugins/userDataManager/userDataManager.utils.differences'
import { FirebaseFS } from '@/database'
import {
    doc,
    getDoc,
    QuerySnapshot
} from 'firebase/firestore'
import { createPersonalisedBalanceModel } from '@/library/scripts/utils/balanceModel'
import { handleDatabaseErrors } from '@/library/scripts/utils/database'
import { createURLPath } from '@/library/scripts/utils/string'
import { createMissingMonthlyBalanceRecords } from '@/library/scripts/plugins/userDataManager/actions/balanceRecord'
import {
    useUserRecipes,
    useUserConfig,
    useUserPersonalData,
    useUserProductsStore,
    useUserBalanceModel,
    useUserBalanceRecords
} from '@/store/user'
import { useLoadingScreenInterface } from '@/store/interface'
import {
    cloneDeep,
    isEmptyObject
} from '@/library/scripts/utils/object'
import { createPersonalisedNutrientsAndCompleteness } from '@/library/scripts/utils/balanceRecord'

/* Config */
import { NUTRIENTS_MODEL_MAP } from '@/library/scripts/values/nutrients'
import { ROUTES } from '@/router/router.config'
import {
    FIRESTORE_CHANGE_TYPES,
    FIRESTORE_COLLECTIONS
} from '@/library/scripts/plugins/userDataManager/userDataManager.config'

/* Types */
import { LoadedData } from '@/store/user/loadedData/types'
import { PersonalData } from '@/store/user/personalData/types'
import { BalanceModel } from '@/library/scripts/types/balanceModel'
import { BalanceRecordSnapshotChanges } from '@/library/scripts/plugins/userDataManager/userDataManager.types'
import {
    BalanceRecord, BalanceRecordsMonth
} from '@/library/scripts/types/balanceRecord'
import { UserProducts } from '@/store/user/products/types'
import { Recipes } from '@/library/scripts/types/recipe'
import { Config } from '@/store/user/config/types'

async function loadBalanceModel (
    currentPersonalData: PersonalData,
    updatedPersonalData: PersonalData,
    loadedData: LoadedData
): Promise<any> {
    const pathToModel = getBalanceModelPath(updatedPersonalData)
    const nutrientsModelMap = NUTRIENTS_MODEL_MAP
    const balanceModelPath = createURLPath(FIRESTORE_COLLECTIONS.balanceModels, pathToModel)
    const balanceModelRef = doc(FirebaseFS, balanceModelPath)

    await getDoc(balanceModelRef)
        .then(response => {
            const balanceModel = response.data() as BalanceModel
            const personalisedBalanceModel = createPersonalisedBalanceModel(balanceModel, updatedPersonalData, nutrientsModelMap)
            const userBalanceModel = useUserBalanceModel()

            userBalanceModel.$patch({
                model: personalisedBalanceModel
            })

            createPersonalisedNutrientsAndCompleteness(personalisedBalanceModel)

            loadedData.balanceModel = true
        })
        .catch((error) => {
            /*
            In case user connections fails during the new balanceModel load restore personalData to reflect current balanceModel */
            const userPersonalData = useUserPersonalData()

            userPersonalData.$patch({
                ...currentPersonalData
            })

            handleDatabaseErrors(error)
        })
}

export function handleConfigChanges (
    config: Config,
    changeType: string,
    loadedData: LoadedData
): void {
    const userConfig = useUserConfig()

    userConfig.$patch({
        ...config
    })

    if (changeType === FIRESTORE_CHANGE_TYPES.added) {
        loadedData.config = true
    }
}

export function handleUserProductsChanges (
    userProducts: UserProducts,
    changeType: string,
    loadedData: LoadedData
): void {
    const userProductsStore = useUserProductsStore()

    userProductsStore.products = userProducts

    if (changeType === FIRESTORE_CHANGE_TYPES.added) {
        loadedData.products = true
    }
}

export function handleUserRecipesChanges (
    recipes: Recipes,
    changeType: string,
    loadedData: LoadedData
) {
    const userRecipesStore = useUserRecipes()

    userRecipesStore.recipes = recipes

    if (changeType === FIRESTORE_CHANGE_TYPES.added) {
        loadedData.userRecipes = true
    }
}

export async function handlePersonalDataChanges (
    personalData: PersonalData,
    loadedData: LoadedData
): Promise<any> {
    if (!isEmptyObject(personalData)) {
        const userPersonalData = useUserPersonalData()
        const currentPersonalData = cloneDeep({
            ...userPersonalData.$state
        })

        userPersonalData.$patch({ ...personalData })
        loadedData.personalData = true

        await loadBalanceModel(currentPersonalData, personalData, loadedData)
    } else {
        const loadingScreenInterface = useLoadingScreenInterface()

        navigateTo(ROUTES.personalise)
            .then(() => {
                loadingScreenInterface.visibility = false
            })
    }
}

export function handleBalanceRecordsChanges (
    changes: BalanceRecordSnapshotChanges,
    loadedData: LoadedData
): void {
    const { data, changeType, isEmpty, timestamp } = changes
    const userBalanceRecords = useUserBalanceRecords()

    switch (true) {
        case isEmpty:
            createMissingMonthlyBalanceRecords(timestamp)
            break
        case changeType === FIRESTORE_CHANGE_TYPES.added:
            userBalanceRecords.addRecords({
                timestamp,
                records: data as BalanceRecordsMonth
            })
            loadedData.balanceRecords = true
            break
        case changeType === FIRESTORE_CHANGE_TYPES.modified: {
            const currentBalanceRecord = userBalanceRecords.getRecord(timestamp)
            const modifiedBalanceRecord = Object.values(data)[0] as BalanceRecord
            const differences = getBalanceRecordMealsPlanDifferences(currentBalanceRecord.mealsPlan, modifiedBalanceRecord.mealsPlan)

            differences.products.forEach((difference) => {
                userBalanceRecords.updateRecordWithProduct({
                    timestamp,
                    difference
                })
            })


            differences.recipes.forEach((difference) => {
                userBalanceRecords.updateRecordWithRecipe({
                    timestamp,
                    difference
                })
            })
        }
    }
}

export function handleRecipesChanges (
    querySnapshot: QuerySnapshot
) {
    const { data } = getRecipesDataSnapshotChanges(querySnapshot)

    data.authors && saveAuthors(data.authors)
    data.recipes && saveRecipes(data.recipes)
}
