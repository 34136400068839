export const ERROR_CODES = {
    noConnection: `auth/network-request-failed`,
    accountExists: `auth/account-exists-with-different-credential`,
    credentialAlreadyInUse: `auth/credential-already-in-use`,
    invalidActionCode: `auth/invalid-action-code`,
    emailInvalid: `auth/invalid-email`,
    tooManyRequest: `auth/too-many-requests`,
    unavailable: `unavailable`
}

export const ERROR_MESSAGES = {
    credentialsInuse: `The credentials you've provided is already in use`,
    emailInUse: `The email address you've provided is already in use`,
    emailInvalid: `The email address you've provided is invalid`,
    emailNotFound: `The email address you've provided is not registered`,
    passwordsDontMatch: `Passwords don't match`,
    sameEmails: `Your currently saved email address is the same as the newly provided one`,
    wrongConfirmationText: `Please check if you've correctly entered:`,
    wrongCredentials: `Email and password don't match`
}
