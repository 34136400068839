/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { LoadedData } from './types'

const STATE: LoadedData = {
    balanceModel: false,
    balanceRecords: false,
    config: false,
    personalData: false,
    userProducts: false,
    products: false,
    recipes: false,
    userRecipes: false
}

export const useUserLoadedData = defineStore(`user/loadedData`, {
    state: () => {
        return {
            ...cloneDeep(STATE)
        }
    }
})
