/* Logic */
import { defineStore } from 'pinia'
import { cloneDeep } from '@/library/scripts/utils/object'

/* Types */
import { UserAuthentication } from '@/store/user/authentication/types'

export function getInitialUserAuthentication (): UserAuthentication {
    return cloneDeep({
        authenticated: false,
        initialised: false,
        creationTimestamp: {
            year: 0,
            month: 0,
            day: 0
        },
        email: ``,
        id: ``,
        online: false
    })
}

export const useUserAuthentication = defineStore(`user/authentication`, {
    state: () => ({
        ...getInitialUserAuthentication()
    })
})
