/* Logic */
import {
    cloneDeep,
    hasOwnProperty
} from '@/library/scripts/utils/object'
import { isString } from '@/library/scripts/utils/types'
import { useRecipesStore } from '@/store/recipes'
import { useProductsStore } from '@/store/products'
import {
    findProductMeasure,
    findRecipeMeasure
} from '@/library/scripts/utils/measures'
import {
    useUserBalanceModel,
    useUserBalanceRecords
} from '@/store/user'

/* Config */
import { CONVERSION_RATES } from '@/library/scripts/values/conversion'
import {
    BALANCE_MODEL_UNITS,
    FOOD_MEASURE_UNITS
} from '@/library/scripts/values/units'
import { NUTRIENTS_MODEL_MAP } from '@/library/scripts/values/nutrients'
import { BALANCE_RECORD_COMPLETENESS } from '@/library/scripts/plugins/userDataManager/userDataManager.config'
import {
    BALANCE_RECORD_PROGRESS_CONTAINER_CATEGORIES,
    BALANCE_RECORD_NUTRIENT_SECTIONS
} from '@/library/scripts/values/balanceRecord'

/* Types */
import {
    BalanceRecord,
    BalanceRecordNutrients,
    BalanceRecordTimestamp,
    BalanceRecordCompleteness,
    FirebaseBalanceRecord, BalanceRecordSection
} from '@/library/scripts/types/balanceRecord'
import {
    BalanceModel,
    BalanceModelProgressSection,
    isBalanceModelProgressSection,
    isBalanceModelSection
} from '@/library/scripts/types/balanceModel'
import { BalanceRecords } from '@/store/user/balanceRecords/types'
import { Nutrients } from '@/library/scripts/types/nutrients'


const CONFIG = {
    percentage: {
        zero: 0,
        hundred: 100
    }
}

export function getConvertedQuantity (quantity: number, unit: string): number {
    const convertedQuantity = quantity / CONVERSION_RATES[unit]
    let roundedConvertedQuantity

    switch (true) {
        case unit === FOOD_MEASURE_UNITS.l && convertedQuantity >= 1:
        case unit === FOOD_MEASURE_UNITS.g && convertedQuantity >= 1:
        case unit === FOOD_MEASURE_UNITS.mg && convertedQuantity >= 100:
        case unit === FOOD_MEASURE_UNITS.mcg && convertedQuantity >= 1:
            roundedConvertedQuantity = Math.round(convertedQuantity)
            break
        case unit === FOOD_MEASURE_UNITS.l && convertedQuantity < 1:
        case unit === FOOD_MEASURE_UNITS.g && convertedQuantity < 1:
        case unit === FOOD_MEASURE_UNITS.mg && convertedQuantity < 100:
        case unit === FOOD_MEASURE_UNITS.mcg && convertedQuantity < 1:
            roundedConvertedQuantity = convertedQuantity.toFixed(2)
            break
        default:
            roundedConvertedQuantity = Math.round(convertedQuantity)
    }

    return Number(roundedConvertedQuantity) /* Remove insignificant trailing zeros */
}

function updateContainerSectionValues (
    containerSection: BalanceRecordSection,
    containerModelSection: BalanceModelProgressSection,
    containerSectionQuantityModifier: number
) {
    const { converted } = containerSection

    containerSection.quantity += containerSectionQuantityModifier
    containerSection.percentage = Number(
        (containerSection.quantity /
            (converted.recommended * containerModelSection.containedSectionsQuantity / 100)
        ).toFixed(2)
    )

    converted.quantity = containerSection.percentage
}

function _updateBalanceRecordCompleteness (
    balanceRecordCompleteness: BalanceRecordCompleteness,
    balanceRecordNutrients: BalanceRecordNutrients
): void {
    const { nutrients, water, energy } = balanceRecordCompleteness

    energy.current = Math.round(balanceRecordNutrients.energy.quantity)
    water.current = Math.round(balanceRecordNutrients.water.quantity)

    energy.percentage = energy.current / (energy.recommended / 100)
    water.percentage = water.current / (water.recommended / 100)
    nutrients.percentage = nutrients.currentNutrientsPercentage / nutrients.totalNutrientsQuantity

    Object.keys(balanceRecordCompleteness).forEach(sectionName => {
        const completenessSection = balanceRecordCompleteness[sectionName]
        const { percentage } = completenessSection

        switch (true) {
            case percentage < CONFIG.percentage.zero:
                completenessSection.percentage = CONFIG.percentage.zero /* Avoid 0.00 from toFixed(2) */
                break
            case percentage >= CONFIG.percentage.hundred:
                completenessSection.percentage = CONFIG.percentage.hundred
                break
            default:
                completenessSection.percentage = Number(
                    percentage.toFixed(2)
                )
        }
    })
}

function updateBalanceRecordNutrientsCompleteness (
    initialNutrientSectionsPercentage: number,
    nutrientSection: BalanceRecordSection,
    balanceRecord: BalanceRecord,
    nutrientSectionName: string,
    containerSectionName: string
): void {
    const userBalanceModel = useUserBalanceModel()
    const { nutrients } = balanceRecord.completeness
    const currentNutrientSectionsPercentage = nutrientSection.percentage
    let nutrientSectionPercentageChange = currentNutrientSectionsPercentage - initialNutrientSectionsPercentage

    /*
    Detect change within 0-100% of nutrient section recommendation */
    if (initialNutrientSectionsPercentage < CONFIG.percentage.hundred &&
        currentNutrientSectionsPercentage > CONFIG.percentage.hundred) {
        /*
        Detect change from 100%+ to 0-100% percent of nutrient section recommendation */
    } else if (nutrientSectionPercentageChange < 0 &&
        initialNutrientSectionsPercentage > CONFIG.percentage.hundred &&
        currentNutrientSectionsPercentage < CONFIG.percentage.hundred) {
        nutrientSectionPercentageChange = currentNutrientSectionsPercentage - CONFIG.percentage.hundred
    }

    nutrients.currentNutrientsPercentage += nutrientSectionPercentageChange

    /*
    Update container sections that depend on the values stored in the child sections */
    if (BALANCE_RECORD_PROGRESS_CONTAINER_CATEGORIES.includes(containerSectionName)) {
        const containerModelSection = userBalanceModel.model[containerSectionName]

        if (isBalanceModelProgressSection(containerModelSection)) {
            const containerSection = balanceRecord.nutrients[containerSectionName]

            updateContainerSectionValues(
                containerSection,
                containerModelSection,
                nutrientSectionPercentageChange
            )
        }
    }
}

/*
Currently, some products contain more values that balance record might use, for this reason the logic checks
for containerSectionName in order to confirm that nutrient is used in balance record */
export function updateBalanceRecordNutrientsAndCompleteness (
    balanceRecord: BalanceRecord,
    itemNutrients: Nutrients,
    load: number
) {
    const { nutrients } = balanceRecord
    const userBalanceModel = useUserBalanceModel()
    const { model } = userBalanceModel

    Object.keys(itemNutrients).forEach(sectionName => {
        const containerSectionName = NUTRIENTS_MODEL_MAP[sectionName]
        const modelSection = model[sectionName]

        if (isString(containerSectionName) && isBalanceModelSection(modelSection)) {
            const itemSection = itemNutrients[sectionName]
            const nutrientsSection = nutrients[sectionName] as BalanceRecordSection
            const { unit } = modelSection.DRI
            const itemNutrientSectionQuantity = itemSection * load
            const initialNutrientSectionPercentage = nutrientsSection.percentage

            nutrientsSection.quantity += itemNutrientSectionQuantity
            nutrientsSection.percentage = Number(
                (nutrientsSection.quantity /
                    (nutrientsSection.converted.recommended / 100) /
                    CONVERSION_RATES[unit])
                    .toFixed(2)
            )

            if (Number(nutrientsSection.percentage) >= CONFIG.percentage.hundred) {
                nutrientsSection.percentage = CONFIG.percentage.hundred
            }

            /*
            Convert mcg to DRI corresponding unit and round the result */
            nutrientsSection.converted.quantity = getConvertedQuantity(nutrientsSection.quantity, unit)
            nutrientsSection.converted.unit = unit

            /*
            Update balance record nutrients completeness */
            if (BALANCE_RECORD_NUTRIENT_SECTIONS.includes(sectionName)) {
                updateBalanceRecordNutrientsCompleteness(
                    initialNutrientSectionPercentage,
                    nutrientsSection,
                    balanceRecord,
                    sectionName,
                    containerSectionName
                )
            }
        }
    })

    _updateBalanceRecordCompleteness(balanceRecord.completeness, nutrients)
}

export function getLastStoredBalanceRecordTimestamp (records: BalanceRecords): BalanceRecordTimestamp {
    const ObjectKeysToNumbers = (objectKeys: string[]): number[] => {
        return objectKeys.map(key => Number(key))
    }

    const year = Math.max(...ObjectKeysToNumbers(Object.keys(records)))
    const month = Math.max(...ObjectKeysToNumbers(Object.keys(records[year])))
    const day = Math.max(...ObjectKeysToNumbers(Object.keys(records[year][month])))

    return {
        year,
        month,
        day
    }
}

export function createBalanceRecordNutrients (model: BalanceModel): BalanceRecordNutrients {
    const nutrients: BalanceRecordNutrients = {}

    for (const sectionName in model) {
        if (hasOwnProperty(model, sectionName)) {
            const section = model[sectionName]

            if (section) {
                let sectionValues = {} as BalanceRecordSection

                if (isBalanceModelSection(section)) {
                    const { RDA, EAR, AI, unit } = section.DRI

                    sectionValues = {
                        percentage: CONFIG.percentage.zero,
                        quantity: 0,
                        converted: {
                            quantity: 0,
                            recommended: 0,
                            unit
                        }
                    }

                    switch (true) {
                        case RDA > 0:
                            sectionValues.converted.recommended = Number(RDA)
                            break
                        case EAR > 0:
                            sectionValues.converted.recommended = Number(EAR)
                            break
                        case AI > 0:
                            sectionValues.converted.recommended = Number(AI)
                    }
                } else if (isBalanceModelProgressSection(section)) {
                    sectionValues = {
                        quantity: 0,
                        percentage: CONFIG.percentage.zero,
                        converted: {
                            quantity: CONFIG.percentage.zero,
                            recommended: CONFIG.percentage.hundred,
                            unit: BALANCE_MODEL_UNITS.percentage
                        }
                    }
                }

                nutrients[sectionName] = sectionValues
            }
        }
    }

    return nutrients
}

export function createBalanceRecordCompleteness (balanceModel: BalanceModel): BalanceRecordCompleteness {
    const completeness = cloneDeep(BALANCE_RECORD_COMPLETENESS)
    const { nutrients, water, energy } = completeness

    nutrients.totalNutrientsQuantity = BALANCE_RECORD_NUTRIENT_SECTIONS.length
    energy.recommended = Number(balanceModel.energy.DRI.RDA)
    water.recommended = Number(balanceModel.water.DRI.AI) * CONVERSION_RATES[FOOD_MEASURE_UNITS.l]

    return completeness
}

export function createPersonalisedNutrientsAndCompleteness (balanceModel: BalanceModel) {
    const nutrients = createBalanceRecordNutrients(balanceModel)
    const completeness = createBalanceRecordCompleteness(balanceModel)
    const userBalanceRecords = useUserBalanceRecords()

    userBalanceRecords.$patch({
        personalisedNutrients: nutrients,
        personalisedCompleteness: completeness
    })
}

export function createBalanceRecordValues (
    firebaseBalanceRecord: FirebaseBalanceRecord
) {
    const userBalanceRecords = useUserBalanceRecords()
    const balanceRecord = cloneDeep({
        mealsPlan: firebaseBalanceRecord.mealsPlan,
        nutrients: userBalanceRecords.personalisedNutrients,
        completeness: userBalanceRecords.personalisedCompleteness
    })
    const recipesStore = useRecipesStore()
    const productsStore = useProductsStore()

    /*
    Restore nutrients values from the stored products and recipes */
    Object.keys(balanceRecord.mealsPlan).forEach(mealPlanName => {
        const { recipes, products } = balanceRecord.mealsPlan[mealPlanName]

        products.forEach((product) => {
            const storeProduct = productsStore.getProduct(product.id)

            if (storeProduct) {
                const measure = findProductMeasure(storeProduct.measures, product.measureId)

                if (measure) {
                    const selectedQuantity = product.quantity * measure.weight

                    updateBalanceRecordNutrientsAndCompleteness(
                        balanceRecord,
                        storeProduct.nutrients,
                        selectedQuantity
                    )
                }
            }
        })

        recipes.forEach((recipe) => {
            const storeRecipe = recipesStore.getRecipe(recipe.id)

            if (storeRecipe) {
                const measure = findRecipeMeasure(storeRecipe.measures, recipe.measureId)

                if (measure) {
                    const selectedQuantity = recipe.quantity * measure.weight

                    updateBalanceRecordNutrientsAndCompleteness(
                        balanceRecord,
                        storeRecipe.nutrients,
                        selectedQuantity
                    )
                }
            }
        })
    })

    return balanceRecord
}

