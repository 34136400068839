/* Types */
import {
    NutrientsListByContainerCategory,
    NutrientsModelMap
} from '@/library/scripts/types/nutrients'

export function createNutrientsModelMap (NutrientsListByContainerCategory: NutrientsListByContainerCategory) {
    const nutrientsMap: NutrientsModelMap = {}

    Object.keys(NutrientsListByContainerCategory).forEach(nutrientName => {
        const nutrient = NutrientsListByContainerCategory[nutrientName]

        Object.values(nutrient).forEach(containedNutrientName => {
            nutrientsMap[containedNutrientName] = nutrientName
        })

        nutrientsMap[nutrientName] = ``
    })

    return nutrientsMap
}
