export const CONFIG = {
    transitionDuration: {
        mobile: {
            enter: 180,
            leave: 180
        },
        nonMobile: {
            enter: 0,
            leave: 0
        }
    }
}
