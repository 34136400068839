/* Logic */
const pluralizeModule = require(`pluralize`)

export function capitalizeString (string: string): string {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return ``
}

export function splitCamelCaseString (string: string): string {
    return string.replace(/([A-Z])/g, ` $1`)
}

export function createURLPath (...string: string[]): string {
    return string.join(`/`)
}

export function isEmptyString (string: string) {
    return string.length === 0
}

export function pluralize (string: string, number: number): string {
    return pluralizeModule(string, number)
}

export function truncateString (string: string, length: number): string {
    return string.length > length ? `${string.slice(0, length - 3)}...` : string
}
