/* Logic */
import { defineComponent } from 'vue'

/* Types */
import { ObjectWithBooleans } from '@/library/scripts/types/types'

export default defineComponent({
    props: {
        active: {
            type: Boolean,
            default: false
        },
        inactive: {
            type: Boolean,
            default: false
        },
        italic: {
            type: Boolean,
            default: false
        },
        capitalize: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            default: `h1`
        }
    },
    computed: {
        classes (): ObjectWithBooleans {
            const defaultClasses = `font-medium transition-color duration-action`

            return {
                'text-primary': !this.active && !this.inactive,
                'text-active': this.active,
                'text-inactive': this.inactive,
                italic: this.italic,
                capitalize: this.capitalize,
                [defaultClasses]: true
            }
        }
    }
})
