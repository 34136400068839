export function isPWA () {
    let isPWA = false

    if (window.matchMedia(`(display-mode: standalone)`).matches) {
        isPWA = true
    }

    return isPWA
}

export function isIOS () {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
}

export function isIOSPWA () {
    return isIOS() && isPWA()
}
