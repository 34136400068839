/* Config */
import { ROUTES } from '@/router/router.config'

const NON_FUNCTIONAL_ROUTES = [
    ROUTES.privacyPolicy,
    ROUTES.termsOfService
]

export const CONFIG = {
    NON_FUNCTIONAL_ROUTES,
    defaultBackButtonText: `Back`,
    navigationVisibilityThreshold: 100,
    suggestAppDelay: 30000
}
